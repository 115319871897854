import React from "react";
import { matchPath } from "react-router-dom";
import './courseCard.css';
import { mediaurl } from "../../redux/sagas/utils";

// import { addFavourite, removeFromFavourites } from '../actions';

const CourseCard = ({ title, duration, description, time, image }) => {
  const imageurl = image ? mediaurl+image : "https://source.unsplash.com/user/erondu";
  return (
    <div>
      <div>
        <div>
          <div class="card" style={{ width: '300px', height: '350px' }}>
            <img class="card-img" src={imageurl} alt="Bologna" style={{ height: "100%" }} />
            <div class="card-img-overlay text-white d-flex flex-column justify-content-center" style={{ marginTop: '190px' }}>
              {/* <h1 class="card-title" style={{color:"black"}}>{title}</h1> */}
              <h1 class="card-subtitle mb-2" style={{ color: 'white' }}>{title}</h1>
              <p class="card-text" style={{ color: 'white' }}>{description}</p>
              <div style={{ backgroundColor: 'white', margin: '20px', borderRadius: '20px', display: "flex", justifyContent: 'space-between', padding: '10px' }}>
                <a href="#" class="card-link" style={{ color: "black", fontSize: '20px' }}>{time||"Fulltime"}</a>
                <a href="#" class="card-link" style={{ color: "black", fontSize: '20px' }}>{duration||"4"}weeks</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="main-card">
    //   <div className="secondLead" style={{backgroundImage: `url(${imageurl})`,backgroundSize: "cover"}}>
    //     <div className="bottom-div">
    //       <h6 style={{fontSize: '15px'}}>{title}</h6>
    //       <p style={{fontSize: '10px'}}>{description}</p>
    //       <div className="duration">
    //         <p>{time}</p>
    //         <p>{duration}</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // <div className="main-card" style={{width: '300px',height: '350px'}}>
    //   <div className="secondLead">
    //     <img src={imageurl} alt = "CourseImg" />
    //   </div>
    //   <div className="bottom-div">
    //     <h3>  </h3>
    //     <p> FREEEEDOM </p>
    //   </div>
    // </div>
  );
}

export default CourseCard;