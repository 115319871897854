import React, { useEffect, useState } from "react";
import CustomSidebar from "../sidebar";
import Navbar from "../navbar";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersInitiate } from "../../redux/actionCreators/userActions";
import UserTable from "./userTable";
import { toaster } from "../../redux/sagas/utils";

const Users = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('user list')
  const [search,setSearch] = useState("")
  const [searchres,setSearchRes] = useState("")
  
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getAllUsersInitiate())
  },[])
  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }
  
  const handleCancel = () => {
    setSearchRes('')
    setSearch('')
  }
  
  const {userList} = useSelector(state => state.userList)
  const handleSubmit = () => {
  if(search.trim()){
      console.log(userList)
      const newArr = userList.filter((itm)=> itm.email === search)
      console.log(newArr)
      if(newArr.length){
        setSearchRes(newArr)
        return
      }
      toaster("success","could not find anything for your search")
      return
    }
  }
  const width = siddebar ? '350px' : '100px'
  const data = searchres ? searchres : userList

  return (
    <div>
      <Navbar
        inputstate={search}
        inputchange={(value) => setSearch(value)}
        handleSubmit={handleSubmit}
        changeSidebar={changeSelected}
      />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
        <div style={{marginLeft: width,marginTop: '96px',width: '100%'}}>
          <UserTable
            back={handleCancel}
            searched={searchres ? true : false}
            show={siddebar}
            userList={data}
          />
        </div>
      </div>
    </div>
  );
}
export default Users;