import './index.css'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { ReactComponent as DashboardBlack } from '../../Icon/dashboard-black.png';
import courseBlack from '../../Icon/Courses-black.png';
import courseswhite from '../../Icon/Courses-white.png';
import pagesblack from '../../Icon/pages-black.png';
import pageswhite from '../../Icon/pages-white.png';
import transactionhistoryblack from '../../Icon/Transaction-history-black.png';
import transactionhistorywhite from '../../Icon/Transaction-history-white.png';
import usrlistblack from '../../Icon/User-list-black.png';
import usrlistwhite from '../../Icon/User-list-white.png';
import dashboardblack from '../../Icon/dashboard-black.png';
import dashboardwhite from '../../Icon/dashboard-white.png';
import reviewblack from '../../Icon/Review-black.png';
import reviewwhite from '../../Icon/Review-white.png';






const CustomSidebar = ({show, link,selected, changeSelected }) => {
  const history = useHistory()
  const Fullsidebar = () => {
    return(
      <div className="side-main" style={{backgroundColor: 'black',    marginTop: '96px'}}>
        <Link
          to="/admin/home"
          onClick={() =>changeSelected('dashboard')}
          className="firstchild" style={selected === 'dashboard' ? selectedStyles : notselectedStyles}>
          <div className="randomsvg">
            <img src={selected === 'dashboard' ? dashboardblack : dashboardwhite} />
          </div>
          <div>
            <div
            > Dashboard
            </div>
          </div>
        </Link>


        <Link
          to="/admin/courses"
          className="firstchild"
          style={selected === 'courses' ? selectedStyles : notselectedStyles}
          onClick={() =>changeSelected('courses')}>
          <div className="randomsvg">
          <img src= {selected === 'courses' ? courseBlack : courseswhite} alt="pic" />
          </div>
          <div>
            <div
            >Courses</div>
          </div>
        </Link>

        <Link to="/admin/users" className="firstchild" style={selected === 'user list' ? selectedStyles : notselectedStyles}>
          <div className="randomsvg">
            <img src={selected === 'user list'? usrlistblack : usrlistwhite } />
          </div>
          <div>
            <div
              onClick={() =>changeSelected('user list')}
            >User List</div>
          </div>
        </Link>

        {/* <div className="firstchild" style={selected === 'notifications' ? selectedStyles : notselectedStyles}>
          <div className="randomsvg">
            <Bell />
          </div>
          <div>
            <div
              onClick={() =>changeSelected('notifications')}
            >Notifications</div>
          </div>
        </div>

        <div className="firstchild" style={selected === 'certificates' ? selectedStyles : notselectedStyles}>
          <div className="randomsvg">
            <Certificates />
          </div>
          <div>
            <div
              onClick={() =>changeSelected('certificates')}
            >Certificates</div>
          </div>
        </div> */}

        <Link
          to="/admin/contactus"
          className="firstchild"
          style={selected === 'contactus' ? selectedStyles : notselectedStyles}
          onClick={() =>changeSelected('contactus')}
        >
          <div className="randomsvg">
            <img src={selected === 'contactus' ? reviewblack: reviewwhite} />
          </div>
          <div>
            <div>Contact Us</div>
          </div>
        </Link> 

        <Link
          to="/admin/transactions"
           className="firstchild"
          style={selected === 'transaction history' ? selectedStyles : notselectedStyles}
          onClick={() =>changeSelected('transaction history')}
        >
          <div className="randomsvg">
            <img src={selected === 'transaction history' ? transactionhistoryblack: transactionhistorywhite} />
          </div>
          <div>
            <div>Transaction History</div>
          </div>
        </Link>


        <Link
          to="/admin/pages"
          className="firstchild"
          style={selected === 'pages' ? selectedStyles : notselectedStyles}
          onClick={() => changeSelected('pages')}
        >
          <div className="randomsvg">
            <img src={selected === 'pages' ? pagesblack : pageswhite} />
          </div>
          <div>
            <div>Pages</div>
          </div>
        </Link>

      </div> 
    )
  }

  const ShortSideBar = () => {

    return(
      <div className="side-short" style={{marginTop: '96px'}}>
        <Link
          to='/admin/home'
          className="firstchild"
          onClick={() =>changeSelected('dashboard')}
          style={selected === 'dashboard' ? selectedStyles : notselectedStyles}>
          <div
            className="randomsvg">
            <img src={selected === 'dashboard' ? dashboardblack : dashboardwhite} />
          </div>
        </Link>


        <Link
          to="/admin/courses"
          className="firstchild"
          style={selected === 'courses' ? selectedStyles : notselectedStyles}>
          <div
            onClick={() =>changeSelected('courses')}
            className="randomsvg">
            <img src= {selected === 'courses' ? courseBlack : courseswhite} alt="pic" />
          </div>
        </Link>

        <Link
          to="/admin/users"
          className="firstchild"
          style={selected === 'user list' ? selectedStyles : notselectedStyles}>
          <div
            onClick={() =>changeSelected('user list')}
            className="randomsvg">
            <img src={selected === 'user list'? usrlistblack : usrlistwhite } />
          </div>
        </Link>

        {/* <div className="firstchild" style={selected === 'notifications' ? selectedStyles : notselectedStyles}>
          <div
            onClick={() =>changeSelected('notifications')}
            className="randomsvg">
            <Bell />
          </div>
        </div>

        <div className="firstchild" style={selected === 'certificates' ? selectedStyles : notselectedStyles}>
          <div
            onClick={() =>changeSelected('certificates')}
            className="randomsvg">
            <Certificates />
          </div>
        </div>

        <div className="firstchild" style={selected === 'review' ? selectedStyles : notselectedStyles}>
          <div
            onClick={() =>changeSelected('review')}
            className="randomsvg">
            <Review />
          </div>
        </div> */}

        <Link
          to="/admin/contactus"
          className="firstchild"
          style={selected === 'contactus' ? selectedStyles : notselectedStyles}
          // onClick={() =>selection('transaction history','/transactions')}
        >
          <div
            className="randomsvg">
            <img src={selected === 'contactus' ? reviewblack: reviewwhite} />
          </div>
        </Link>

        <Link
          to="/admin/transactions"
          className="firstchild"
          style={selected === 'transaction history' ? selectedStyles : notselectedStyles}
          // onClick={() =>selection('transaction history','/transactions')}
        >
          <div
            className="randomsvg">
            <img src={selected === 'transaction history' ? transactionhistoryblack: transactionhistorywhite} />
          </div>
        </Link>


        <Link
          to="/admin/pages"
          className="firstchild"
          onClick={() =>changeSelected('/pages')}
          style={selected === 'pages' ? selectedStyles : notselectedStyles}
        >
          <div
            className="randomsvg">
            <img src={selected === 'pages' ? pagesblack : pageswhite} />
          </div>
        </Link>

      </div> 
    )
  }
  const selectedStyles = {
    backgroundColor: 'white',
    color: 'black'
  }
  const notselectedStyles = {
    backgroundColor: 'black',
    color: 'white'
  }

  return(
    <div>
      { show ? 
      <Fullsidebar />
      : 
      <ShortSideBar />
    }
    </div>
  )
}
export default CustomSidebar