import React, { useEffect, useState } from "react";
import CustomSidebar from "../sidebar";
import Navbar from "../navbar";
import CustomDashboard from "../Dashboard";
import { batch, useDispatch, useSelector } from "react-redux";
import { fetchstaticPageInitiate } from "../../redux/actionCreators/staticPageActions";

const Home = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('dashboard')
  const {staticpagelist} = useSelector(state => state.staticpageState)
  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }

  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchstaticPageInitiate())
    })
  },[])

  return (
    <div>
      <Navbar changeSidebar={changeSelected} />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
      <CustomDashboard show={siddebar} />
      </div>
    </div>
  );
}
export default Home;