import React, { useEffect, useState } from "react";
import CustomSidebar from "../sidebar";
import Navbar from "../navbar";
import CustomDashboard from "../Dashboard";
import { batch, useDispatch, useSelector } from "react-redux";
import { fetchstaticPageInitiate } from "../../redux/actionCreators/staticPageActions";
import CourseList from "../Home/courseList";
import { fetchCourseInitiate, getCourseDetailsInitiate } from "../../redux/actionCreators/courseActions";
import CourseDetails from "./courseDetails";
import { useHistory } from "react-router";

const CoursesDetailsDisplay = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('dashboard')
  const {staticpagelist} = useSelector(state => state.staticpageState)
  
  const changeSelected =() => {
    setSiddebar(!siddebar);
  }
  const history = useHistory();
  const dispatch = useDispatch();
  const url = history.location.pathname
  const urlarr = url.split('/')
  const courseId = urlarr[urlarr.length -1]
  useEffect(() => {
    batch(()=>{
      dispatch(getCourseDetailsInitiate(courseId));
    })
  },[])
  
  const {courseDetails} = useSelector(state => state.courseList)
  const width = siddebar ? '350px' : '100px'
  console.log(courseDetails)
  return (
    <div>
      <Navbar changeSidebar={changeSelected} />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
        <div style={{marginLeft: width , marginTop: '96px', width: '100%'}}>
          <CourseDetails details={courseDetails} show={siddebar} />
        </div>
      </div>
    </div>
  );
}
export default CoursesDetailsDisplay;