import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { stringvalidator } from '../../redux/sagas/utils';
import { Button, Form, FormGroup, Label, Row, Input, Col } from 'reactstrap';

import { values } from 'lodash';
import { addcourseInitiate, editCourseInitiate } from '../../redux/actionCreators/courseActions';

function AddEditForm(props) {
  const dispatch = useDispatch()
  const [courseform, setcourseform] = useState({
    id: '',
    name: '',
    description: '',
    time: '',
    duration: '',
    price: '',
    image: null,
    courseMaterial: null
  })
  console.log(props)

  const validator = () => {
    if(props.formtype === 'course'){
      const name = stringvalidator(courseform.name)
      const description = stringvalidator(courseform.description)
      const time = stringvalidator(courseform.time)
      const duration = stringvalidator(courseform.duration)
      // const price = stringvalidator(price)
      if(name === false || description === false || time === false || duration === false || courseform.image === null || courseform.courseMaterial === null){
      toast('Please fill the form compleately')
      return false
    }
      return true 
    }
  }
  
  const onChange = (e) => {
    if(props.formtype === "course"){
      if(e.target.name === 'image'){
        console.log(e.target.files[0])
        setcourseform({
          ...courseform,
          image: e.target.files[0]
        })
        return
      }
      if(e.target.name === 'material'){
        console.log(e.target.files)
        setcourseform({
          ...courseform,
          courseMaterial: e.target.files[0]
        })
        return
      }
      setcourseform({
        ...courseform,
        [e.target.name]: e.target.value
      })
    }
  }
  
  const submitFormAdd = e => {
    e.preventDefault()

    console.log(courseform)
    const next = validator()
    if(next === false){
      return
    }

    if(props.formtype === 'course'){
      dispatch(addcourseInitiate(courseform))
      props.toggle()
    }
  }


  const submitFormEdit = e => {
    e.preventDefault()
    const next = validator()
    if(next === false){
      return
    }
    console.log("subnite edit")

    if(props.formtype === 'course'){
      dispatch(editCourseInitiate(courseform))
      props.toggle()
    }
  }
    
    useEffect(() => {
      if(props.item && props.formtype === 'course'){
        setcourseform({
          ...courseform,
          id: props.item._id,
          name: props.item.course_title,
          description: props.item.course_description,
          time: props.item.time,
          duration: props.item.duration,
          price: props.item.course_price,
          image: null,
          courseMaterial: null
        })
      }
    }, [])
        
  if(props.formtype === 'course'){
  return(
    <Form onSubmit={Object.entries(props.item).length !== 0 ? submitFormEdit : submitFormAdd}>
      <Row>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Title</Label>
        <Input type="text" name="name" id="first" onChange={onChange} value={courseform.name} />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Description</Label>
        <Input type="text" name="description" id="first" onChange={onChange} value={courseform.description} />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Time</Label>
        <Input type="text" name="time" id="first" onChange={onChange} value={courseform.time} />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Duration</Label>
        <Input type="text" name="duration" id="first" onChange={onChange} value={courseform.duration} />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Price</Label>
        <Input type="number" name="price" id="first" onChange={onChange} value={courseform.price} />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Image</Label>
        <Input type="file" name="image" id="first" onChange={onChange}  />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Course Material</Label>
        <Input type="file" name="material" id="first" onChange={onChange}/>
      </FormGroup>
      </Col>
      </Row>
      <Button>Submit</Button>
    </Form>
    )
  }
  return(
    <h1>Something went wrong, UnAuthorized Access</h1>
  )
}

export default AddEditForm