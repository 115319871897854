import { ReactComponent as ArrowTop } from '../../assets/ArrowTop.svg';
import { ReactComponent as PDF } from '../../assets/PDF.svg';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/DeleteIcon.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersInitiate, toggleActivationInitiate } from '../../redux/actionCreators/userActions';
import moment from 'moment';


const StudentTable = () => {
  const tableHeaderArr = ['Image', 'Name', 'Phone', 'Email' ,'Status' , 'Date' , 'Actions']
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getAllUsersInitiate())
  },[])
  
  const handleActivation = (event,id,currentStatus) => {
    event.stopPropagation()
    const payload= {
      id: id,
      status: !currentStatus
    }
    dispatch(toggleActivationInitiate(payload))
  }
  const {userList} = useSelector(state => state.userList)
  return (
    <div style={{padding: '5px' }}>
      <div style={{boxShadow: '4px 3px 8px 1px #969696'}}>
      <div style={{display: 'flex',height: '45px',padding: '4px',alignItems: 'center',fontWeight: '700'}}>
      <p><ArrowTop /> New Student List</p>
      </div>
      <table class="table table-striped">
        <thead class='thead-dark'>
          <tr>
            {tableHeaderArr.map((ind) => (
              <th scope="col">{ind}</th>  
            ))}
          </tr>
        </thead>
        <tbody>
          {userList.map((ind) => (
            <tr>
              <th scope="row">{ind.profileImage || '-'}</th>
              <th scope="row">{ind.userName || '-'}</th>
              <th scope="row">{ind.contactNo || '-'}</th>
              <th scope="row">{ind.email || '-'}</th>
              <th scope="row">{ind.status ? 'Active' : 'InActive'}</th>
              <th scope="row">{moment(ind.createdAt).format("MM/DD/YYYY") || '-'}</th>
              {/* <th scope="row"><span><PDF /></span></th> */}
              <th>
                {/* <span><EditIcon />  </span> */}
                <span onClick={(event) => handleActivation(event,ind._id,ind.status)}><DeleteIcon /></span>
              </th>
            </tr>  
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

export default StudentTable;