import React from "react";
import "./footer.css"
import { FaGooglePlusG, FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import logo from "../../Utils/Group 11011@2x.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getStaticContent } from "../../services/api";


function Footer() {
    const [footer_text, setFooter_text] = useState([]);
    const [footer_contact_addr, setFooter_contact_addr] = useState([]);
    const [footer_contact_no, setFooter_contact_no] = useState([]);
    const [footer_footer_copyright, setFooter_footer_copyright] = useState([]);




    useEffect(() => {
        const fetchData = async () => {
            const response_footer_text = await getStaticContent("footer_text");
            const response_footer_contact_addr = await getStaticContent("footer_contact_addr");
            const response_footer_contact_no = await getStaticContent("footer_contact_no");
            const response_footer_copyright = await getStaticContent("footer_copyright");


            // console.log(response_footer_contact_addr)
            if (response_footer_text) {
                setFooter_text(response_footer_text.data.message[0]);
            }
            if (response_footer_contact_no) {
                // console.log()
                setFooter_contact_addr(response_footer_contact_addr.data.message[0]);
            }
            if (response_footer_contact_no) {
                setFooter_contact_no(response_footer_contact_no.data.message[0]);
            }
            if (response_footer_copyright) {
                setFooter_footer_copyright(response_footer_copyright.data.message[0]);
            }
        }
        fetchData();
    },[])

    // console.log(footer_footer_copyright);

    return (

        <>
            {/* <!-- Footer --> */}
            <footer className="text-center text-lg-start  text-muted">
                {/* <!-- Section: Social media --> */}

                {/* <!-- Section: Social media --> */}

                {/* <!-- Section: Links  --> */}
                <section className="footb">
                    <div className="container-fluid text-center text-md-start mt-5">
                        {/* <!-- Grid row --> */}
                        <div className="row  mt-3">
                            {/* <!-- Grid column --> */}
                            <div className="col-md-3 col-lg-3 col-xl-3   column1">
                                {/* <!-- Content --> */}
                                <img className=" mb-4 logoapptist" src={logo} />
                                <hr className='horizontallineapp1' />

                                <p className='text-light'>
                                    {footer_text && footer_text.description || "Lorem Ipsum is simply dummy text of the printing."}
                                </p>
                            </div>
                            {/* <!-- Grid column --> */}

                            {/* <!-- Grid column --> */}
                            <div className="col-md-2 col-lg-2 col-xl-2  mb-4 column2">
                                {/* <!-- Links --> */}
                                <h6 className="text-uppercase fw-bold mb-4 text-light ">
                                    Explore                                </h6>
                                <hr className='horizontallineapp2' />


                                <p className="text-light">
                                    <Link to="/" className="text-reset text-light links ">Home</Link>
                                </p>
                                <p className="text-light">
                                    <Link to="/courses" className="text-reset text-light links">Course</Link>
                                </p>
                                <p className="text-light">
                                    <Link to="/About" className="text-reset text-light links">About Us</Link>
                                </p>
                                <p className="text-light">
                                    <Link to="/contact" className="text-reset text-light links">Contact Us</Link>
                                </p>
                            </div>
                            {/* <!-- Grid column --> */}
                            {/* <!-- Grid column --> */}
                            <div className="col-md-2 col-lg-2 col-xl-2  mb-4 column3">
                                {/* <!-- Links --> */}
                                <h6 className="text-uppercase text-white fw-bold mb-4">
                                    Follow
                                </h6>
                                <hr className='horizontallineapp3' />
                                <div className='line22323'>
                                    <a href="#!" ><FaFacebookF className="icon" /></a>
                                    <a href="#!" ><FaTwitter className="icon" /></a>
                                    <a href="#!" ><FaInstagram className="icon" /></a>
                                </div>
                            </div>
                            {/* <!-- Grid column --> */}

                            {/* <!-- Grid column --> */}
                            <div className="col-md-2 col-lg-2 col-xl-2  mb-4 column4">
                                {/* <!-- Links --> */}
                                <h6 className="text-uppercase fw-bold mb-4 text-light">
                                    For You
                                </h6>
                                <hr className='horizontallineapp4' />

                                <p>
                                    <a href="#!" className="text-light light">Pricing</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-light light">Settings</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-light light">Orders</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-light light">Help</a>
                                </p>
                            </div>
                            {/* <!-- Grid column --> */}

                            {/* <!-- Grid column --> */}
                            <div className="col-md-3 col-lg-3 col-xl-3  mb-md-0 mb-4 column5">
                                {/* <!-- Links --> */}
                                <h6 className="text-uppercase text-light fw-bold mb-4">
                                    Contact
                                </h6>
                                <hr className='horizontallineapp5' />

                                <p className="text-light light">{footer_contact_addr && footer_contact_addr.description ||"123 St Lorem Ipsum is simply dummy text of the printing."}</p>

                                <p className="text-light light">
                                {footer_contact_no && footer_contact_no.title ||"+ 01 234 567 88"}</p>
                                <p className="text-light light">{footer_contact_no && footer_contact_no.description ||"+ 01 234 567 89"} </p>
                            </div>
                            {/* <!-- Grid column --> */}
                        </div>
                        {/* <!-- Grid row --> */}
                    </div>
                </section>
                {/* <!-- Section: Links  --> */}

                {/* <!-- Copyright --> */}
                <div class="text-center p-2 last" >

                    <a className="copyright">{footer_footer_copyright&&footer_footer_copyright.description||"Copyright © Lorem Ipsum is simply 2021. All Rights Reserved."}</a>
                    <div className="last1">
                        <FaFacebookF className="iconfoot" />
                        <FaGooglePlusG className="iconfoot" />
                        <FaTwitter className="iconfoot" />
                        <FaInstagram className="iconfoot" />

                    </div>
                </div>
                {/* <!-- Copyright --> */}
            </footer>
            {/* <!-- Footer --> */}


        </>
    );
}

export default Footer;
