import { staticPageActions } from "../actionTypes";

const intitalState = {
  aboutusData: {},
  staticpagelist: [],
  privacyPolicy: null,
  termsOfUse: null,
};

export const staticpageReducer = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {
    case staticPageActions.ADD_STATIC_PAGE_INITIATE : {
      return {
        ...state,
      };
    }
    case staticPageActions.ADD_STATIC_PAGE_SUCCESS: {
      const {resData} = payload
      const newObj = {
        _id: resData.id,
        title: resData.title,
        metaKeyword: resData.metaKeyword,
        metaDescription: resData.metaDescription,
        description: resData.description,
      }
      return {
        ...state,
        staticpagelist: [...state.staticpagelist, newObj]
      };
    }
    case staticPageActions.DELETE_STATIC_PAGE_INITIATE:{
      return {
        ...state
      }
    }
    case staticPageActions.DELETE_STATIC_PAGE_SUCCESS:{
      const res = state.staticpagelist.filter((itm) => itm._id !== payload.payload)
      return {
        ...state, staticpagelist: res
      }
    }
    case staticPageActions.FETCH_STATIC_PAGE_INITIATE : {
      return {
        ...state,
      };
    }
    case staticPageActions.FETCH_STATIC_PAGE_SUCCESS: {
      const {data} = payload
      return {
        ...state,
        staticpagelist: [...data]
      };
    }
    case staticPageActions.FETCH_TERMS_OF_USE_INITIATE : {
      return {
        ...state,
      };
    }
    case staticPageActions.FETCH_TERMS_OF_USE_SUCCESS: {
      console.log(payload)
      const {data} = payload
      return {
        ...state,
        termsOfUse: [...data]
      };
    }
    case staticPageActions.FETCH_PRIVACY_POLICY_INITIATE : {
      return {
        ...state,
      };
    }
    
    case staticPageActions.FETCH_PRIVACY_POLICY_SUCCESS: {
      console.log(payload)
      const {data} = payload
      return {
        ...state,
        privacyPolicy: [...data]
      };
    }

    case staticPageActions.EDIT_STATIC_PAGE_INITIATE: {
      return {
        ...state,
      };
    }

    case staticPageActions.EDIT_STATIC_PAGE_SUCCESS:{
      const {data} = payload
      const newObj = {
        _id: data.id,
        title: data.title,
        metaDescription: data.metaDescription,
        metaKeyword: data.metaKeyword,
        description: data.description
      }
      let index = state.staticpagelist.findIndex( element => {
        if (element._id === newObj._id) {
          return true;
        }
        return false
      });
      const newArray = [...state.staticpagelist];
      newArray.splice(index, 1, newObj);
      return {
        ...state,
        staticpagelist: newArray
      }
    }

// ----------------------------product list fetch actions-----------------------------
    default:{
      return {
        ...state,
      };
    }
  }
};
