import { coursesActions } from "../actionTypes";

const intitalState = {
  courses: [],
  courseDetails: {},
  transaction: [],
  currentOrder:{}
};

export const courseReducer = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {

    case coursesActions.ADD_TRANSACTION_INITIATE:{
      return{

      }
    }
    case coursesActions.ADD_TRANSACTION_SUCCESS:{
      return{
        ...state,
        transaction: [...state.transaction,payload.data]
      }
    }
    case coursesActions.GET_COURSE_INITIATE: {
      return {
        ...state,
      };
    }
    case coursesActions.GET_COURSE_SUCCESS:{
      console.log(payload.data)
      return {
        ...state,
        courses: payload.data,
      };
    }
    case coursesActions.ADD_COURSE_INITIATE: {
      return {
        ...state,
      };
    }
    case coursesActions.ADD_COURSE_SUCCESS:{
      console.log(payload)
      const newcourseList = [...state.courses,payload.data]
      return {
        ...state,
        courses: newcourseList,
      };
    }
    case coursesActions.GET_COURSE_DETAILS_INITIATE: {
      return {
        ...state,
      };
    }
    case coursesActions.GET_COURSE_DETAILS_SUCCESS:{
      return {
        ...state,
        courseDetails: payload.data,
      };
    }
    case coursesActions.DELETE_COURSE_INITIATE: {
      return {
        ...state,
      };
    }
    case coursesActions.DELETE_COURSE_SUCCESS:{
      const res = state.courses.filter((itm) => itm._id !== payload.payload)
      return {
        ...state, courses: res
      }
    }
    case coursesActions.EDIT_COURSE_INITIATE: {
      console.log("reducer,edit")
      return {
        ...state,
      };
    }
    case coursesActions.EDIT_COURSE_SUCCESS: {
      console.log(payload.data)
      return {
        ...state,
        courseDetails: payload.data
      }
    }
    case coursesActions.GENERATE_ORDER_INITIATE: {
      return {
        ...state,
      };
    }
    case coursesActions.GENERATE_ORDER_SUCCESS:{
      console.log(payload)
      return {
        ...state, currentOrder: payload.orderData
      }
    }
    default:
    return {
      ...state,
    };
  }
};
