// fetchproductsubcategory
import {put} from "redux-saga/effects"
import { fetchOrdersSuccess } from "../actionCreators/orderActions";
import { makereq, toaster } from "./utils";

export function* fetchAllOrderssSaga({payload}) {
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`order/allOrders`,'get',{},headers)
    console.log(res)
    const {data} = res
    yield put(fetchOrdersSuccess({data}))
  }catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error)
  }
}