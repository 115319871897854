// import React from 'react';
import "./DetailCourse.css"
import time from "../../Utils/Group 510859.png";
import SKILL from "../../Utils/Group 511023.png";
import LECTURES from "../../Utils/Group 511024.png";
import ENROLLED from "../../Utils/Group 511025.png";

import Card from '../home/Course_card';
import Contactform from '../Contactus/contactform';
import Footer from '../footer/footer';
import Navbar from '../Navbar/Navbar';
import { useEffect, useState, React } from "react";
import { getAllposts, gettransectionById, getPostById } from "../../services/api";
import { useHistory, useParams } from "react-router";
import axios from "axios";


function DetailCourse() {
    let { id } = useParams();
    const history = useHistory();
    const [post, setPost] = useState({});
    const [login, setLogin] = useState(null);
    const [purchase, setPurchase] = useState(false);
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const fetchData = async () => {

            const login = JSON.parse(localStorage.getItem('userInfo'))
            if (login) {
                setLogin(login);
                let token = login.data.data.token

                // for getting course is purchased or not
                // const checkpurchase = await axios.get(`http://localhost:3000/api/transaction/${id}`);
                const checkpurchase = await gettransectionById(id, token);
                console.log(checkpurchase)
                if (checkpurchase.data.data[0]) {
                    let user = checkpurchase.data.data[0]
                    // console.log(user.user)
                    setUserId(user.user);
                    setPurchase(true);

                }
            }

            // for getting course detail
            // const data = await axios.get(`http://localhost:3000/api/course/courseDetails/${id}`);
            const data = await getPostById(id);
            if (data.data.data) {
                // console.log(data.data.data);
                setPost(data.data.data);
            }
        }
        fetchData();
    }, []);
    // console.log(post);
    // console.log(post.course_title);
    let title = post.course_title || "No Title";

    const tocheckout = () => {
        if (login) {
            history.push(`/checkout/${post._id}`);
        } else {
            alert("Login first");
            history.push(`/signup`);
        }

    }
    const topurchase = () => {
        if (login) {
            history.push(`/purchase_course/${userId}`);
        } else {
            alert("Login first");
            history.push(`/signup`);
        }

    }
    // console.log(purchase);
    // console.log(userId);
    // console.log(token);




    //;

    return (
        <>


            <div className='container-fluid'>

                <Navbar />
                <div className="Detailbanner1">
                    <div className="Detailbanner">
                        <h1 className="head">{title}</h1>
                        <p className="para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                        {purchase ?
                            <button type="button" class="btn btn-lg btn-warning text-light fw-bold" onClick={topurchase}>Already purchased</button>
                            :
                            <button type="button" class="btn btn-lg btn-warning text-light fw-bold" onClick={tocheckout}>Buy Now</button>
                        }
                        {/* <button type="button" class="btn btn-lg btn-warning text-light fw-bold" onClick={tocheckout}>Buy Now</button> */}
                    </div>
                </div>

                <div>
                    <div className="row Detaillinecont">
                        <div className="col col-lg-3 col-md-6">
                            <div className="Detailheads">
                                <img src={time}></img>
                                <div className="Detailtexta">
                                    <h1 className="Detailtexth">Duration</h1>
                                    <p className="Detailtextp">10 week</p>
                                </div>
                                <div className="Detailline"></div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6">
                            <div className="Detailheads">
                                <img src={SKILL}></img>
                                <div className="Detailtexta">
                                    <h1 className="Detailtexth " style={{ "fontSize": "34px" }}>Skill Level</h1>
                                    <p className="Detailtextp">Intermediate</p>
                                </div>
                                <div className="Detailline"></div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6">
                            <div className="Detailheads">
                                <img src={LECTURES}></img>
                                <div className="Detailtexta">
                                    <h1 className="Detailtexth" >LECTURES</h1>
                                    <p className="Detailtextp">6 lessons    </p>
                                </div>
                                <div className="Detailline"></div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6">
                            <div className="Detailheads">
                                <img src={ENROLLED}></img>
                                <div className="Detailtexta">
                                    <h1 className="Detailtexth">ENROLLED</h1>
                                    <p className="Detailtextp">20 students  </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='Detailcourse'>
                <h1 className='Detailcourseh' >Course Description</h1>

                <p className='Detailcoursep'>{post.course_description}Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including Remaining Essentially versions of Lorem Ipsum. It Was Popularised In The 1960S With The Release Of Letraset Sheets Containing</p>

            </div>
            <div className='DetailLEARN'>
                <h1 className='DetailLEARNh' >WHAT YOU WILL LEARN</h1>

                <p className='DetailLEARNp'>{post.course_Content}Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>

            </div>
            {/* <div><img src={post.courseFile}></img></div> */}

            <section className="whatyouget container-fluid  ">

                <div className="row">
                    <div className="col-lg-6 c0l-md-12">
                        <div className="leftsidedetail">
                            <h1>What You Get</h1>
                            <p>Software Architecture</p>
                            <p>Timesheets</p>
                            <p>Video Lessons</p>
                        </div>

                    </div>

                    <div className="col-lg-6 c0l-md-0">
                        <div className="workingdetail">
                            <h1 className="workingdetailh1">Working Hours</h1>


                            <table className="table">

                                <tbody>
                                    <tr>
                                        <td className="text-start tablecontent" scope="row">Monday</td>
                                        <td className="text-end tablecontent">9am - 6pm</td>

                                    </tr>
                                    <tr>
                                        <td className="text-start tablecontent" scope="row">tuesday</td>
                                        <td className="text-end tablecontent">9am - 6pm</td>

                                    </tr>
                                    <tr>
                                        <td className="text-start tablecontent" scope="row">Wednesday</td>
                                        <td className="text-end tablecontent" colspan="2">9am - 6pm</td>

                                    </tr>
                                    <tr>
                                        <td className="text-start tablecontent" scope="row">Thursday</td>
                                        <td className="text-end tablecontent" colspan="2">9am - 6pm</td>

                                    </tr>
                                    <tr>
                                        <td className="text-start tablecontent" scope="row">Friday</td>
                                        <td className="text-end tablecontent" colspan="2">9am - 6pm</td>

                                    </tr>
                                    <tr>
                                        <td className="text-start tablecontent" scope="row">Saturday</td>
                                        <td className="text-end " colspan="2">
                                            <p class="btn btn-dark tablecontentbut">Closed</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className="text-start tablecontent" scope="row">Sunday</td>
                                        <td className="text-end" colspan="2">
                                            <p class="btn btn-dark tablecontentbut">Closed</p>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>


                </div>

            </section>
            <div className='DetailLEARNPopular'>
                <h1 className='DetailLEARNhPopular' >Popular Courses</h1>

                <p className='DetailLEARNpPopular'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>

            </div>



            <Contactform />
            <Footer />





        </>
    );
}

export default DetailCourse;




