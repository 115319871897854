import { orderActions } from "../actionTypes";

export const fetchOrdersInitiate = (data) => {
  return {
    type: orderActions.FETCH_ORDER_INITIATE,
    payload: data,
  };
};

export const fetchOrdersSuccess = (data) => {
  return {
    type: orderActions.FETCH_ORDER_SUCCESS,
    payload: data,
  };
};