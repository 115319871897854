import { contactUSAction } from "../actionTypes";

export const getContactUsInitiate = (data) => {
  return {
    type: contactUSAction.FETCH_CONTACT_US_INITIATE,
    payload: data,
  };
};

export const getContactUsSuccess = (data) => {
  console.log(data,"IMOP")
  return {
    type: contactUSAction.FETCH_CONTACT_US_SUCCESS,
    payload: data,
  };
};

export const editContactUsInitiate = (data) => {
  console.log("action initiated",data,"gddg")
  return {
    type: contactUSAction.EDIT_CONTACT_US_INITIATE,
    payload: data,
  };
};

export const editContactUsSuccess = (data) => {
  console.log("Last action initiated")
  return {
    type: contactUSAction.EDIT_CONTACT_US_SUCCESS,
    payload: data,
  };
};
