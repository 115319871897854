import { post } from "jquery";
import React from "react";
import { useHistory } from "react-router-dom";


// imported component
import Mask from "../../Utils//wes-hicks-4-EeTnaC1S4-unsplash.jpg";
import svg from "../../Utils/Group 10893.svg";
import './Course_card.css';
import { mediaurl } from "../../services/api";

// import { useLocation } from "react-router-dom";




function Card(post) {
    const history = useHistory();
    // console.log(post.post.course_title);
    let courseimage = mediaurl + post.post.courseImage;
    // console.log(post);
    // console.log(mediaurl + post.post.courseImage);
    const gotoCheckoutpage = () => {
        history.push("/checkout");
    }

    return (
        <>
            <section>
                <div className="row my-4 mx-2 test ">
                    <div className="p-0 position-relative card1">
                        <div className="position-relative img1223123" >
                            {/* style={{backgroundImage:`${courseimage || Mask}`}}> */}
                            <figure className="figure">
                                <img src={post.post.courseImage ? courseimage : Mask} className="img-fluid img1111" alt="..." />
                            </figure>
                            <div className="position-absolute translate-middle tagimg">

                                <img src={svg} className="img-fluid" alt="..."></img>
                            </div>
                            <h1 className="position-absolute translate-middle tagfs" >
                                {post.post.course_price ? "$" + post.post.course_price : null}
                            </h1>

                        </div>
                        <div className="test1">
                            <p className="text-white fw-bold  title "> {post.post.course_title}</p>
                            <p className="text-white text-sm  text">
                                {post.post.course_content}
                            </p>

                            <div className="buttag">
                                <span class="rounded-pill bg-light text-dark tag1">OnlineLive</span>
                                <span class="rounded-pill bg-secondary text-light tag2">Classroom</span>
                                <button type="button" class="btn btn-warning  butcards12334 " onClick={gotoCheckoutpage}>Cart</button>
                            </div>
                            <div className="for">
                                <p className="bg-white fw-bold forp ">
                                    <span className=''>{post.post.time || "Full Time"}</span>
                                    <span className="sp1">{post.post.duration || 4} Week</span>
                                </p>

                            </div>



                        </div>

                    </div>

                </div>
            </section>



        </>
    );
}

export default Card;
