import { put } from "redux-saga/effects"
import {
  addcourseSuccess,
  editCourseSuccess,
  fetchCourseSuccess,
  deleteCourseSuccess,
  generateOrderSuccess,
  getCourseDetailsSuccess
} from "../actionCreators/courseActions";

import { makereq, toaster } from "./utils";
import { values } from "lodash";
var qs = require('qs')

export function* fetchCourseSage({ payload }) {
  console.log('saga')
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('/course', 'get', null, headers)
    const data = res.data
    console.log(res, "kkk")       
    yield put(fetchCourseSuccess({ data }))
  }
  catch (error) {
    console.log(error)
    error.message && error.message === "invalid token ..."
      ? toaster("error", "please login agian")
      : toaster("error", "Error  Occured")
  }
}

export function* addcourseSaga({ payload }) {
  try {
    const formData = new FormData();
    formData.append('course_title', payload.name);
    formData.append('course_description', payload.description);
    formData.append('price', payload.price);
    formData.append('duration', payload.duration);
    formData.append('time', payload.time);
    formData.append('course_price', payload.price);
    formData.append("courseMedia", payload.image, payload.image.name);
    formData.append("courseVideo", payload.courseMaterial, payload.courseMaterial.name)
    const headers = {
      'Content-Type': 'multipart/form-data',
      // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }

    const res = yield makereq(`course/addCourse`, 'post', formData, headers)
    const { data } = res
    yield put(addcourseSuccess({ data }))
    toaster('success', 'Subcategory added successfully')
  } catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error.message)
  }
}

export function* editcourseSaga({ payload }) {
  try {
    console.log(payload.courseMaterial)
    const formData = new FormData();
    formData.append('course_title', payload.name);
    formData.append('course_description', payload.description);
    formData.append('price', payload.price);
    formData.append('duration', payload.duration);
    formData.append('time', payload.time);
    formData.append('course_price', payload.price);
    formData.append("courseMedia", payload.image, payload.image.name);
    formData.append("courseVideo", payload.courseMaterial, payload.courseMaterial.name)

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`course/${payload.id}`, 'post', formData, headers)
    console.log(res, "edit res")
    const { data } = res
    yield put(editCourseSuccess({ data }))
    toaster('success', 'Course Edited successfully')
  } catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error.message)
  }
}

export function* getcourseDetailsSaga({ payload }) {
  try {
    console.log(payload)
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`course/courseDetails/${payload}`, 'get', {}, headers)
    console.log(res)
    const { data } = res
    yield put(getCourseDetailsSuccess({ data }))
  } catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error.message)
  }
}

export function* deletecourseSaga({ payload }) {
  try {
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`course/${payload}`, 'delete', {}, headers)
    console.log(res)
    const { data } = res
    yield put(deleteCourseSuccess({ data }))
  } catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error.message)
  }
}

// export function generateOrderSaga({payload}){
//   try {
//     var dataa = qs.stringify({
//       'name': payload.name,
//       'description': payload.description 
//     });
//     const headers= { 
//       'Content-Type': 'multipart/form-data', 
//       'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
//     }
//     const res = makereq('/order','post',dataa,headers)
//     console.log(res)
//     const {data} = res
//     yield put(generateOrderSuccess({data}))
//   } catch (error) {
//     error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
//     console.log(error.message)
//   }
// }
export function* generateOrderSaga({ payload }) {
  console.log(payload.courseId, payload.amount)
  try {
    var data = qs.stringify({
      'courseId': payload.courseId,
      'amount': payload.amount
    });
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('order/', 'post', data, headers)
    console.log(res)
    const orderData = res.data
    yield put(generateOrderSuccess({ orderData }))
  }
  catch (error) {
    console.log(error)
  }
}

export function* addTransactionSaga({ payload }) {
  try {
    console.log(payload)
    // const headers= { 
    //   'Content-Type': 'multipart/form-data', 
    //   'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    // }
    // const res = yield makereq(`transaction/`,'post',{},headers)
    // console.log(res)
    // const {data} = res
    // yield put(deleteCourseSuccess({data}))
  } catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error.message)
  }
}