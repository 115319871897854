import React, { useState } from "react";
import { Table } from "reactstrap";
import './index.css'
import ReactPaginate from 'react-paginate'

function TransactionTable({show, tabledata}) {
  const [pageNumber,setPageNumber] = useState(0)  
  const shopHeaders = ["Payment Id","Payer Id","Receiver Id","Amount","Status", 'Course Title']
  const usersPerPage = 2
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata ? tabledata.slice(pageVisited, pageVisited+usersPerPage) : []

  const VisualRepresentation = () => {
    return(
      <Table responsive>
        <thead>
          <tr>
            {shopHeaders.map((itm,key) => <th key={key}>{itm}&emsp;&emsp;</th>)}
          </tr>
        </thead>
        <tbody>
        {diplayusers.map((itm,key) => 
          <tr key={key}>
              <td>
               {itm._id}
             </td>
             <td>
               {itm.payerEmail}
             </td>
             <td>
               {itm.payee_email}
             </td>
             <td>
               {itm.currency,itm.amount}
             </td>
             <td>
               {itm.status};
             </td>
             {/* <td> */}
               {/* {itm.courseId._id || "jjjj"} */}
             {/* </td> */}
             <td>
              {itm.courseId.course_title}
             </td>
          </tr>
        )}
      </tbody>
    </Table>
    )
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default TransactionTable;