import { ReactComponent as Meter } from '../../assets/Meter.svg';
const SecondHeader = () => {
  return(
    <div style={{marginTop: '96px'}}>
      <div style={{display: 'flex',justifyContent: 'space-between'}}>
        <div style={{color: 'black', verticalAign:'middle'}}>
          <h1>Dashboard</h1>
        </div>
        <div>
          <ol className="breadcrumb sm-right">
            <i aria-hidden='true' style={{margin: '0px 10px'}}><Meter /></i>
            <li className="breadcrumb-item"><a href="#" style={{color: 'black', fontWeight: '700', fontSize: '20px'}}>Home</a></li>
            <li className="breadcrumb-item active" style={{fontSize: '16px', marginTop:'10px'}}>Student Dashboard</li>
          </ol>
        </div>     
      </div>
    </div>
  )
}
export default SecondHeader