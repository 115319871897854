import { Button } from "reactstrap"
import { mediaurl } from "../../redux/sagas/utils"

const UserCCourses = ({data,searched,back,show}) => {
  return(
    <div class="card mb-3" style={{maxWidth: '540px'}}>
      <div class="row no-gutters" style={{padding: '20px'}}>
        <div class="col-md-4">
          <img src={`${mediaurl}${data.courseImage}`} alt="LOGO Course" style={{width: '95%', height: '95%',objectFit: "cover", overflow: "hidden"}} />
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title" style={{textDecoration: 'underline',fontSize: '20px'}}>{data.course_title}</h5>
            <p class="card-text" >{data.course_description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserCCourses