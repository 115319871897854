import { put } from "redux-saga/effects";
import { editSettingsSuccess, fetchSettingsSuccess } from "../actionCreators/settingsActions";
import { makereq, toaster } from "./utils";
var qs = require('qs');

export function* fetchSettingsSaga({payload}) {
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('settings','get',null,headers)
    const {data} = res
    yield put(fetchSettingsSuccess({data}))
  } 
  catch (error) {
    console.log(error)
  }
}

export function* editSettingsSaga({payload}) {
  try {
    
    const form = qs.stringify({
      'name': payload.name,
      'email': payload.email,
      'password': payload.password,
      'sitename': payload.sitename,
      'smtpemail': payload.smtpemail,
      'smtppassword': payload.smtppassword 
    });
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`settings/${payload.id}`,'put',form,headers)
    console.log(res,"saga")
    const {data} = res
    console.log(form)
    yield put(editSettingsSuccess({data}))
    toaster('success',"Settings Updated")
  } 
  catch (error) {
    console.log(error)
  }
}