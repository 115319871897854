import './index.css'
import { ReactComponent as SearchIcon } from '../../assets/Iconsearch.svg';

const Search = ({inputstate,inputchange, handleSubmit}) => {
  return(
    <div className="search-container">
      <input
        value={inputstate}
        onChange={(e) => inputchange(e.target.value)} 
        style={{backgroundColor: 'white', color: 'black'}}
        className="search-input"
        placeholder="Search Here ..." >
      </input>
      <div className="search-button" onClick={handleSubmit}>
        {/* <SearchIcon /> */}
        {/* <i class="fa-fa search"></i> */}
        {/* <i class="fas fa-search" ></i> */}
        <i class="fas fa-search"></i>
      </div>
    </div>
  )
}

export default Search