import Navbar from "../navbar";
import StaticPages from '../StaticPage/staticpage'
import CustomSidebar from "../sidebar";
import React, { useEffect, useState } from "react";
import './index.css'
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactionsInitiate } from "../../redux/actionCreators/transactionActions";
import Transaction from "./transactions";

const TransactionDisplay = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('transaction history')  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchTransactionsInitiate())
  },[])
  console.log(useSelector(state => state))
  return (
    <div>
      <Navbar changeSidebar={changeSelected} />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
      <Transaction show={siddebar} />
      </div>
    </div>
  );
}

export default TransactionDisplay;