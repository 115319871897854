import './index.css'
// import Search from '../Search';
import { Button } from 'reactstrap';
import { toaster } from '../../redux/sagas/utils';
import React, { useEffect, useState } from "react"
import TransactionTable from './transactionTable';
import { useDispatch, useSelector, batch } from "react-redux";
import { fetchTransactionsInitiate } from '../../redux/actionCreators/transactionActions';

const Transaction = ({show}) => {
  const [searchBy, setSearchBy] = useState('');
  const [search, setsearch] = useState('');
  const [searchRes,setSearchRes] = useState('')
  // const {transaction} = useSelector(state => state.transactionState)
  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchTransactionsInitiate());
    })
  },[])

  const {transaction} = useSelector(state => state.transactionState)
  
  // const handleSubmit = () => {
  //   if(searchBy.trim() && search.trim()){
  //     if(searchBy === 'transactionId'){
  //       console.log(searchBy,search)
  //       const newArr = transaction.filter((itm)=> itm._id === search)
  //       if(newArr.length){
  //         setSearchRes(newArr)
  //         return
  //       }
  //     }else if(searchBy === 'receiver'){
  //       const newArr = transaction.filter((itm)=> itm.trnsaction_to === search)
  //       if(newArr.length){
  //         setSearchRes(newArr)
  //         return
  //       }
  //     }else if(searchBy === 'payer'){
  //       console.log(searchBy,search)
  //       const newArr = transaction.filter((itm)=> itm.trnsaction_from === search)
  //       if(newArr.length){
  //         setSearchRes(newArr)
  //         return
  //       }
  //     }
  //     toaster("error","could not find anything for your search")
  //     return
  //   }
  // }
  
  const handleback = () => {
    setSearchRes('')
    setsearch('')
    setSearchBy('')
  }

  const dropdownArray = [
    {
      name: 'Transaction Id',
      value: 'transactionId'
    },
    {
      name: 'Reciever',
      value: 'receiver'
    },
    {
      name: 'Payer',
      value: 'payer'
    }
  ]

  const TableView = () => {
    return(
      <div class="hold-transition skin-blue sidebar-collapse">
        {searchRes &&
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <p>Search Result</p>
          <Button color="primary" onClick={handleback}>Back</Button>
        </div> 
        }
        {
          searchRes
          ? <TransactionTable show={"trnasaction"} tabledata={searchRes}/> 
          : <TransactionTable show={"trnasaction"} tabledata={transaction}/>
        }
      </div>
    )
  }
  const width = show ? '325px' : '84px'
  return(
    <div className="main" style={{marginLeft: width, marginTop: '96px'}}>
      <div className="con" style={{fontSize: '15px'}}>
      <h1>Transactions</h1>
        <div className="container-fluid">
          <TableView />
        </div>
      </div>
    </div>
  )
}

export default Transaction