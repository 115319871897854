import { useHistory } from "react-router-dom";
// import React from 'react';
import "./purchase_course.css";
import Card from '../home/Course_card';
import Contactform from '../Contactus/contactform';
import Footer from '../footer/footer';
import Navbar from '../Navbar/Navbar';
import { useEffect, useState, React } from "react";
import { getStaticContent, getpurchasedcourse } from "../../services/api";
import { Link } from "react-router-dom";
import time from "../../Utils/Group 510859.png";
import SKILL from "../../Utils/Group 511023.png";
import LECTURES from "../../Utils/Group 511024.png";
import ENROLLED from "../../Utils/Group 511025.png";

function Course() {
    const history = useHistory()
    // const [id, setId] = useState(null);
    const [posts, setPosts] = useState([]);
    const [poster_text, setPoster_text] = useState([]);
    const [head_text, setHead_text] = useState([]);


    // const { search } = useLocation();
    useEffect(() => {
        const fetchData = async () => {
            var data = JSON.parse(localStorage.getItem('userInfo'));


            if (data) {
                var data1 = data.data.data;
                // let id = data1._id;
                let token = data1.token;
                // setId(id);
                const response = await getpurchasedcourse(token);
                const response_poster_text = await getStaticContent("purchase_poster_text");
                const response_head_text = await getStaticContent("purchase_head_text");



                if (response) {
                    let purchsedcourse = response.data.data
                    setPosts(purchsedcourse);
                }

               
                 if (response_poster_text) {
                    setPoster_text(response_poster_text.data.message[0]);
                }
                if (response_head_text) {
                    setHead_text(response_head_text.data.message[0]);
                }

            }
            else {
                history.push("/signup");
            }
        }
        fetchData();
    }, []);
    console.log(head_text);


    return (
        <>
            <div className='container-fluid'>

                <Navbar />
                <div className="banner1">
                    <div className="banner">
                        <h1 className="head">{poster_text&&poster_text.title||"LEARN FROM HERE"}</h1>
                        <p className="para">{poster_text&&poster_text.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever."}</p>
                        {/* <button type="button"
                            className="btn btn-lg btn-warning text-light fw-bold buttonelementstyle ">
                            Warning
                        </button> */}
                    </div>
                </div>

                <div>
                    <div className="container-fluid">
                        <div className="row Detaillinecont">
                            <div className="col col-lg-3 col-md-6">
                                <div className="Detailheads">
                                    <img src={time}></img>
                                    <div className="Detailtexta">
                                        <h1 className="Detailtexth">Duration</h1>
                                        <p className="Detailtextp">10 week</p>
                                    </div>
                                    <div className="Detailline"></div>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6">
                                <div className="Detailheads">
                                    <img src={SKILL}></img>
                                    <div className="Detailtexta">
                                        <h1 className="Detailtexth " style={{ "fontSize": "34px" }}>Skill Level</h1>
                                        <p className="Detailtextp">Intermediate</p>
                                    </div>
                                    <div className="Detailline"></div>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6">
                                <div className="Detailheads">
                                    <img src={LECTURES}></img>
                                    <div className="Detailtexta">
                                        <h1 className="Detailtexth" >LECTURES</h1>
                                        <p className="Detailtextp">6 lessons    </p>
                                    </div>
                                    <div className="Detailline"></div>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6">
                                <div className="Detailheads">
                                    <img src={ENROLLED}></img>
                                    <div className="Detailtexta">
                                        <h1 className="Detailtexth">ENROLLED</h1>
                                        <p className="Detailtextp">20 students  </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='seccourses'>
                <h1 className='courseh' >{head_text&&head_text.title || "My Purchased Courses"}</h1>

                <p className='coursep'>{head_text&&head_text.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br />Lorem Ipsum has been the industry's standard dummy."}</p>
                <div className="apptistcontainer">
                    <p className='text-end '>ViewAll</p>
                    <div className="container-fluid">
                        <div className="row">
                            {posts.map(post => (
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <Link to={`/purchased_detail/${post._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                                        <Card post={post} />
                                    </Link>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Contactform />
            <Footer />





        </>
    );
}

export default Course;




