import Search from '../search';
import { ReactComponent as HamIcon } from '../../assets/Search.svg';
import pp from '../../assets/profilepic.png'
import logo from '../../assets/Group_11019.png'
import { ReactComponent as Cart } from '../../assets/Cart.svg';
import { ReactComponent as Bell } from '../../assets/Bell.svg';
import { ReactComponent as Logo } from '../../assets/Rectangle.svg';
import { ReactComponent as Logo2 } from '../../assets/Group_11027.svg';
import { ReactComponent as Envelope } from '../../assets/Envelope.svg';


import './index.css'
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actionCreators/authenticateActions';
import { useHistory } from 'react-router';
// import Sidebar from '../sidebar';
const Navbar = ({changeSidebar,search,inputchange,handleSubmit}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleLogout = () => {
    localStorage.clear()
    dispatch(logout())
    history.push('/admin/sign-in')
  }
  return(
    <div>
      <div className="nav-bar">
      <div className="left-child">
        <div className="hamburger-container">
          <HamIcon onClick={changeSidebar} />
        </div>
        <div className="logo-container" style={{backgroundColor: "white"}}>
          <img src={logo} alt="LOGO" />
        </div>
      </div>
      <div className="right-child">
        <div className="right-left-child">
          <div className="logo2">
            <Logo2 />
          </div>
          <div className="search">
            <Search
              inputstate={search}
              inputchange={inputchange}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
         <div className="right-right-child">
          {/* <div className="wrapper">
            <div>
              <Cart />
              <span className="count">4</span>
            </div>
          </div>
          <div className="wrapper">
            <div>
              <Envelope />
              <span className="count">4</span>
            </div>
          </div>
          <div className="wrapper">
            <div>
              <Bell />
              <span className="count">4</span>
            </div>
          </div> */}
          <div style={{margin: '10px'}}>
            <Button onClick={handleLogout} color="danger">
              Logout
            </Button>
          </div>
          <div className="wrapper">
            <img src={pp} alt="DP" />
          </div>
        </div>
      </div>
    </div>
      
    </div>
  )

}
export default Navbar