import React, { useEffect, useState } from "react";
import CustomSidebar from "../sidebar";
import Navbar from "../navbar";
import { useDispatch, useSelector } from "react-redux";
import { toaster } from "../../redux/sagas/utils";
import ContactusDisplay from "./contactusDisplay";
import { getContactUsInitiate } from "../../redux/actionCreators/contactUSActions";

const ContactUs = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('contactus')
  const [search,setSearch] = useState("")
  const [searchres,setSearchRes] = useState("")
  
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getContactUsInitiate())
  },[])
  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }
  console.log(useSelector(state => state.contactUSData))
  const {contactUSData} = useSelector(state =>state.contactUSData)
  const handleCancel = () => {
    setSearchRes('')
    setSearch('')
  }
  
  const {userList} = useSelector(state => state.userList)
  const handleSubmit = () => {
  if(search.trim()){
      console.log(userList)
      const newArr = userList.filter((itm)=> itm.email === search)
      console.log(newArr)
      if(newArr.length){
        setSearchRes(newArr)
        return
      }
      toaster("success","could not find anything for your search")
      return
    }
  }
  const width = siddebar ? '350px' : '100px'
  const data = searchres ? searchres : userList

  const obj = [
    {
      _id: "dddd",
      contactNo: "Ddd",
      email: "ddd",
      countryCode: "ddddddd",
      address: "dsgsgsgs"
    }
  ]

  return (
    <div>
      <Navbar
        inputstate={search}
        inputchange={(value) => setSearch(value)}
        handleSubmit={handleSubmit}
        changeSidebar={changeSelected}
      />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
        <div style={{marginLeft: width,marginTop: '96px',width: '100%'}}>
          <h1>ContactUs</h1>
          <ContactusDisplay userInfo={contactUSData} />
        </div>
      </div>
    </div>
  );
}
export default ContactUs;