import CourseCard from './courseCard'
import CourseImg from '../../assets/CoursePic1.png'
import { ReactComponent as ArrowTop } from '../../assets/ArrowTop.svg';
import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchCourseInitiate } from '../../redux/actionCreators/courseActions';
import ModalForm from "../Modal";
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';



const CourseList = ({coursesdata,searched,back}) => {
  const [modaldata, setmodaldata] = useState({})
  const [showmodal, setshowmodal] = useState(false)

  const deactivateModal = () => {
    setmodaldata({})
    setshowmodal(false);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCourseInitiate())
  },[])

  const {courses} = useSelector((state) => state.courseList)
  const history = useHistory()
  console.log(history)

  const mapper = coursesdata && coursesdata.length > 0 ? coursesdata : courses

  const ADd = () => {
    if(history.location.pathname === "/admin/home"){
      return (
        <div>

        </div>
      )
    }else{
      return(
        <Button color="success" onClick={() => setshowmodal(true)} style={{margin: '10px 10px 10px 10px'}}>Add Courses</Button>
      )
    }
  }
  console.log(mapper,"koik")

  return(
    <div style={{padding: '50px'}}>
      {showmodal && <ModalForm show={"course"} deactivate={deactivateModal} item={modaldata}/>}
      <ADd />
      <div>
      {searched  &&
        <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '15px', marginRight: '3%'}}>
          <p>Search results</p>
          <Button color="primary" onClick={back}>Go Back</Button>
        </div>
      }
      </div>
      <div style={{boxShadow: '4px 3px 8px 1px #969696',paddingLeft: '20px'}}>
        <p style={{fontWeight: 'bold'}}><ArrowTop />  Latest Courses</p>
        <div style={{display: 'grid',gridTemplateColumns:'auto auto auto auto', columnGap: '2px', rowGap: '20px'}}>
          {mapper && mapper.map((itm)=>(
            <div style={{margin: '20px 20px 20px 20px' }} onClick={() =>history.push(`/admin/courseDetails/${itm._id}`)} >
            <CourseCard
              time={itm.time}
              title={itm.course_title}
              image={itm.courseImage}
              price={itm.course_price}
              duration={itm.duration}
              description={itm.course_description}
            />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default CourseList