import React, { useEffect, useState } from "react";
import CustomSidebar from "../sidebar";
import Navbar from "../navbar";
import CustomDashboard from "../Dashboard";
import { batch, useDispatch, useSelector } from "react-redux";
import { fetchstaticPageInitiate } from "../../redux/actionCreators/staticPageActions";
import CourseList from "../Home/courseList";
import { fetchCourseInitiate } from "../../redux/actionCreators/courseActions";
import { toaster } from "../../redux/sagas/utils";
import { Button } from "reactstrap";

const Courses = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('courses')
  const {courses} = useSelector(state => state.courseList)
  const [search,setSearch] = useState("")
  const [searchres,setSearchRes] = useState("")
  
  
  const handleSubmit = () => {
    if(search.trim()){
        const newArr = courses.filter((itm)=> itm.course_title === search)
        console.log(newArr)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
        toaster("success","could not find anything for your search")
        return
      }
    }
    const handleCancel = () => {
      setSearchRes('')
      setSearch('')
    }
  // console.log(state.courseList,"stateee")
  
  const data = searchres ? searchres : courses
  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }

  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchCourseInitiate())
    })
  },[])
  const width = siddebar ? '350px' : '100px'

  return (
    <div>
      <Navbar
        inputstate={search}
        inputchange={(value) => setSearch(value)}
        handleSubmit={handleSubmit}
        changeSidebar={changeSelected}
      />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
        <div style={{marginLeft: width , marginTop: '96px'}}>
          <CourseList
            back={handleCancel}
            searched={searchres ? true : false}
            coursesdata = {data}
            show={siddebar} />
        </div>
      </div>
    </div>
  );
}
export default Courses;