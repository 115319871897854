import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import "./navbar.css"

//  imported img
import logo from "../../Utils/Group 11023.png";
// import {LogedinContext} from "../login/login";





function Navbar() {

  let history= useHistory;
  // const status =useContext(LogedinContext);
  // console.log(status);
  const [id, setId] = useState(null);
  const [name,setName]=useState("A")
  useEffect(() => { 
    var data = JSON.parse(localStorage.getItem('userInfo'));
    if(data){
      var data1 = data.data.data;
      let id = data1._id;
      let noun=data1.email
      let noun1=noun[0];
      let capital=noun1.toUpperCase();
      // console.log(capital);
      setName(capital);
      setId(id);
    }
  }, []);
  const logoutHandler=()=>{
    localStorage.removeItem('userInfo');
    localStorage.removeItem('__paypal_storage__');    
    window.location.reload(false);    
    history.push("/");
  } 
  const courseHandler = ()=>{
    
  }
  // console.log(name);



  return (

    <>
      <section className="container-fluid">

        <div className="row">
          <div className="col-4 p-0 bgstyle">
            <img className="navbar-brand float-start logo img-fluid" src={logo}></img>

          </div>
          <div className="col-8 p-0">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark fs ">
              <div className="container-fluid ">
                <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse fontnav " id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link nav-linka text-light me-3 tags" to="/" >Home</Link>

                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-linka text-light me-3 tags" to="/courses" >Course</Link>

                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-linka text-light me-3 tags" to="/About" >About Us</Link>

                    </li>

                    <li className="nav-item">
                      <Link className="nav-link nav-linka text-light me-3 tags" to="/contact" >Contact Us</Link>
                    </li>

                  </ul>
                  <ul className="navbar-nav  ">


                    {(id) ? <><li className="nav-item ">
                        {/* <FontAwesomeIcon icon="fa-solid fa-user" / */}
                        <Link className="nav-link nav-linka text-light me-3 tags" onClick={logoutHandler}  >logout</Link>
                      </li>
                      <li className="nav-item ">
                        {/* <FontAwesomeIcon icon="fa-solid fa-user" / */}
                        <Link className="nav-link nav-linka text-light me-3 tags" onClick={courseHandler} to={`/purchase_course/${id}` }>course</Link>
                      </li>
                       <li className="nav-item ">
                      {/* <FontAwesomeIcon icon="fa-solid fa-user" / */}
                      <Link className="nav-link nav-linka text-light me-3 tags tagsprofile" to={`/update_view/${id}`} >{name}</Link>
                    </li>
                    </> : <>
                      <li className="nav-item ">
                        {/* <FontAwesomeIcon icon="fa-solid fa-user" / */}
                        <Link className="nav-link nav-linka text-light me-3 tags" to="/signup" >Sign in</Link>
                      </li>
                      <li className="nav-item ">
                        {/* <FontAwesomeIcon icon="fa-solid fa-user" / */}
                        <Link className="nav-link nav-linka text-light me-3 tags" to="/register" >Register</Link>
                      </li></>}

                  </ul>

                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}

export default Navbar;
