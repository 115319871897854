// import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// imported component from admin
import './App_Admin.css';
import Home from './admin_components/Home';
import StaticPageDisplay from './admin_components/StaticPage';
import Transaction from './admin_components/Transactions';
import AddStaticPageDisplay from './admin_components/StaticPage/addStaticDisplay';
import Courses from './admin_components/Courses'
import CoursesDetailsDisplay from './admin_components/Courses/courseDetailsdiaplay';
import Login from './admin_components/login/login'
import ProtectedRoute from './admin_components/ProtectedRoute';
import Users from './admin_components/Users';
import Notfound from './admin_components/PageNotFound/notfound'
import TermsOfUse from './admin_components/TermsOfUse';
import ContactUs from './admin_components/Contactus';
import PrivacyPolicy from './admin_components/PrivacyPolicy'
import UserCourses from './admin_components/UserCourses'

//import component from userinterface

import Home_user from "./user_components/home/Home";
import contactUs from './user_components/Contactus/Contactus';
import AboutUs from './user_components/Aboutus/About';
import Course from './user_components/Courses/Courses';
import register from './user_components/Register/register';
import signup from './user_components/login/login';
import Checkout from "./user_components/Checkout/Checkout";
import DetailCourse from './user_components/DetailCourse/DetailCourse';
// import Cart from './user_components/Cart/Cart';
import View_update_profile from "./user_components/view_profile/VIew_update_profile";
import purchase_course from './user_components/purchase_course/purchase_course';
// import Notfound from "./user_components/Page_not_fond/notfound";
import Purchased_Detail from "./user_components/purchased_details/purchased_Details"

// import App_Admin from "./App_Admin";
// import User_App from './User_App';



function App() {
    const history = useHistory();
    const [login, setLogin] = useState(null);

    useEffect(() => {
        const login = JSON.parse(localStorage.getItem('userInfo'));
        if (login) {
            setLogin(login);
            console.log(login)
        }
    }, []);

    return (
        <div className="App" class="wrapper">
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home_user} />
                    <Route exact path="/courses" component={Course} />
                    <Route exact path="/contact" component={contactUs} />
                    <Route exact path="/About" component={AboutUs} />
                    <Route exact path="/register" component={register} />
                    <Route exact path="/signup" component={signup} />
                    <Route exact path="/checkout/:id" component={login ? Checkout : signup} />
                    <Route exact path="/detailcourse/:id" component={DetailCourse} />
                    <Route exact path="/purchased_detail/:id" component={Purchased_Detail} />
                    <Route exact path="/update_view/:id" component={login ? View_update_profile : signup} />
                    <Route exact path="/purchase_course/:id" component={login ? purchase_course : signup} />
                    <Route path="/admin/sign-in" exact component={Login} />
                    <ProtectedRoute path="/admin/pages" exact component={StaticPageDisplay} />
                    <ProtectedRoute path="/admin/transactions" exact component={Transaction} />
                    <ProtectedRoute path="/admin/users" exact component={Users} />
                    <ProtectedRoute path="/admin/courses" exact component={Courses} />
                    <ProtectedRoute path="/admin/user/courses/:id" exact component={UserCourses} />
                    <ProtectedRoute path="/admin/courseDetails/:id" component={CoursesDetailsDisplay} />
                    <ProtectedRoute path="/admin/add-static-content" exact component={AddStaticPageDisplay} />
                    <ProtectedRoute path="/admin/home" exact component={Home} />
                    <ProtectedRoute path="/admin/contactus" exact component={ContactUs} />
                    <ProtectedRoute path="/termsofuse" exact component={TermsOfUse} />
                    <ProtectedRoute path="/privacyPolicy" exact component={PrivacyPolicy} />
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                localStorage.getItem('userData')
                                    ? <Redirect to="/admin/home" />
                                    : <Redirect to="/admin/sign-in" />
                            )
                        }}
                    />
                    <Route path='*' exact={true} component={Notfound} />


                    {/* <Route path="/userList" exact component={StudentList} /> */}
                    {/* <Route path="/courses" exact component={CourseListComp} /> */}
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;



