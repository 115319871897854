import { ReactComponent as ArrowTop } from '../../assets/ArrowTop.svg';
import { ReactComponent as PDF } from '../../assets/PDF.svg';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';
import ReactPaginate from 'react-paginate'
import { ReactComponent as DeleteIcon } from '../../assets/DeleteIcon.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersInitiate, toggleActivationInitiate } from '../../redux/actionCreators/userActions';
import { Button } from 'reactstrap';
import moment from 'moment'
import { useHistory } from 'react-router';


const UserTable = ({userList,back,searched,show}) => {
  const [pageNumber,setPageNumber] = useState(0)  
  const usersPerPage = 2
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = userList ? userList.slice(pageVisited, pageVisited+usersPerPage) : []


  const tableHeaderArr = [ 'Name', 'Phone', 'Email' ,'Status' , 'Date' , 'Courses Purchased', 'Actions']
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getAllUsersInitiate())
  },[])

  const handleActivation = (event,id,currentStatus) => {
    event.stopPropagation()
    const payload= {
      id: id,
      status: !currentStatus
    }
    dispatch(toggleActivationInitiate(payload))
  }
  const history = useHistory()
  // const {userList} = useSelector(state => state.userList)
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(userList.length / usersPerPage)
  const VisualRepresentation = () => {
    return(
      <div style={{padding: '5px' }}>
      <div style={{boxShadow: '4px 3px 8px 1px #969696'}}>
      <div style={{display: 'flex',height: '45px',padding: '4px',alignItems: 'center',fontWeight: '700'}}>
      <p><ArrowTop /> New Student List</p>

      <div>
        {searched && 
        <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '15px', marginRight: '3%'}}>
        <Button style={{marginLeft: '100px'}} color="primary" onClick={back}>Go Back</Button>
      </div>
        
        }
      </div>
      </div>
      <table class="table table-striped">
        <thead class='thead-dark'>
          <tr>
            {tableHeaderArr.map((ind) => (
              <th scope="col">{ind}</th>  
            ))}
          </tr>
        </thead>
        <tbody>
          {diplayusers.map((ind) => (
            <tr>
              {/* <th scope="row">{ind.profileImage || '-'}</th> */}
              <th scope="row">{ind.userName || '-'}</th>
              <th scope="row">{ind.contactNo || '-'}</th>
              <th scope="row">{ind.email || '-'}</th>
              <th scope="row">{ind.status ? 'Active' : 'InActive'}</th>
              <th scope="row">{moment(ind.createdAt).format("MM/DD/YYYY") || '-'}</th>
              <th scope="row" onClick={() =>history.push(`user/courses/${ind._id}`)}><span><PDF /></span></th>
              <th>
                {/* <span><EditIcon />  </span> */}
                <span onClick={(event) => handleActivation(event,ind._id,ind.status)}><DeleteIcon /></span>
              </th>
            </tr>  
          ))}
        </tbody>
      </table>
      </div>
    </div>
    )
  }
  return(
    <React.Fragment>
      <VisualRepresentation />
      <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </React.Fragment>
  )
}

export default UserTable;