import React, { useState, useEffect } from 'react';
import { TiLocation } from "react-icons/ti";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { addContact, getStaticContent } from "../../services/api";
import location from "../../Utils/location.png";
import mail from "../../Utils/mail.png";
import phoneimg from "../../Utils/phone.png";

import "./contactform.css";

const intialValues = {
    name: "",
    lastname: "",
    contactNo: "",
    email: "",
    address: "",
    CountryCode: "201"
}

function Contactform() {
    const [contact, setContact] = useState(intialValues);
    const { name, lastname, contactNo, email, address } = contact;


    const onValueChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value })
        console.log(contact)
    };
    const addContactDetails = async (e) => {
        await addContact(contact);
    }

    const [contactform_add, setContactform_add] = useState(intialValues);
    const [contactform_phone, setContactform_phone] = useState(intialValues);
    const [contactform_email, setContactform_email] = useState(intialValues);
    const [contactform_text, setContactform_text] = useState(intialValues);





    useEffect(() => {
        const fetchData = async () => {
            const response_contactform_add = await getStaticContent("contactform_add");
            const response_contactform_phone = await getStaticContent("contactform_phone");
            const response_contactform_email = await getStaticContent("contactform_email");
            const response_contactform_text = await getStaticContent("contactform_text");

            if (response_contactform_add) {
                setContactform_add(response_contactform_add.data.message[0]);
            }
            if (response_contactform_phone) {
                setContactform_phone(response_contactform_phone.data.message[0]);
            }
            if (response_contactform_email) {
                setContactform_email(response_contactform_email.data.message[0]);
            }
            if (response_contactform_text) {
                setContactform_text(response_contactform_text.data.message[0]);
            }
        }
        fetchData();
    }, []);

    // console.log(contactform_text);


    return (
        <>
            <section className="contact">
                <div className="contactdiv">
                    <div className="contactcontainer">
                        <h1 className="contacttitle text-white fw-bold m-4">{contactform_text && contactform_text.title || "Contact Us"}</h1>
                        <p className="contactpa text-white fw-bold">
                            {contactform_text && contactform_text.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br /> Ipsum has been the industry's standard dummy."}
                        </p>
                        <form>
                            <div className="row">
                                <div className="col-md-12 col-lg-6 col-sm-12">
                                    <div className="single-input me-4">
                                        {/* <BsFillPersonFill/> */}
                                        <input type="text" name="name" value={name} placeholder="NAME" onChange={onValueChange} />
                                    </div>
                                </div>

                                <div className="col-md-12 col-lg-6 col-sm-12 ">
                                    <div className="single-input me-4">
                                        {/* <i className="fas fa-user"></i> */}
                                        <input type="text" name="lastname" value={lastname} placeholder="Last Name" onChange={onValueChange} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-sm-12">
                                    <div className="single-input me-4">
                                        {/* <i class="fas fa-phone"></i> */}
                                        <input type="text" name="contactNo" value={contactNo} placeholder="Phone" onChange={onValueChange} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-sm-12 ">
                                    <div className="single-input me-4">
                                        {/* <i className="fas fa-check"></i> */}
                                        <input type="text" name="email" value={email} placeholder="E-mail" onChange={onValueChange} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input me-4">
                                        {/* <i className="fas fa-check"></i> */}
                                        <input type="text" name="address" value={address} placeholder="address" onChange={onValueChange} />
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="submit-input text-start">
                                        <input type="submit" name="submit" value="SUBMIT NOW" onClick={addContactDetails} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="addressline">
                            <div className='addr'>
                                {/* <TiLocation className="location" />

                                <BsFillTelephoneFill className="phone" />

                                <GrMail className="mail" /> */}

                                <img className="locationimg" src={location} alt="..." />


                                <img className="phoneimg" src={phoneimg} alt="..." />
                                <img className="mailimg" src={mail} alt="..." />



                                <hr className="test-dotedline"></hr>
                            </div>
                            <div className='addr1'>
                                <div className="addresstext">
                                    <p className="Htext fw-bold">{contactform_add && contactform_add.title || "address"}</p>
                                    <p className="Ptext"> {contactform_add && contactform_add.description || "lorem ipsum lorem,3000"}</p>
                                </div>
                                <div className="Phonestext">

                                    <p className="Htext fw-bold">{contactform_phone && contactform_phone.title || "Phones"}</p>
                                    <p className="Ptext">{contactform_phone && contactform_phone.description || "010 234 4582"} </p>
                                </div>
                                <div className="emialtext">

                                    <p className="Htext fw-bold">{contactform_email && contactform_email.title || "Email"}</p>
                                    <p className="Ptext"> {contactform_email && contactform_email.description || "Lorem@gmail.com"}</p>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </section>

        </>
    );
}

export default Contactform;





