import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { addPurchase_course, paypalInte, addtransection } from "../../services/api";
import imgcongo from "../../Utils/Congratulation.png"
import "./paypal.css"


export default function Paypal(props, sendDataToParent) {
    const history = useHistory();
    const paypal = useRef();
    let orderplaced = props.par;
    const user_Id = props.par.id;

    let token = props.token;

    console.log(orderplaced);
    console.log(token)
    const [congrats, setCongrats] = useState(null);


    // orderplaced.paymentStatus='success fully completed';
    // orderplaced.status="order placed";
    // console.log(orderplaced)

    useEffect(() => {
        window.paypal
            .Buttons({
                createOrder: (data, actions, err) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                description: "Cool looking table",
                                amount: {
                                    currency_code: "CAD",
                                    // value: 1,
                                            value: orderplaced.amount,
                                },
                            },
                        ],
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    orderplaced.paymentStatus = 'payment successfully completed';
                    orderplaced.status = "order completed";
                    console.log(order);
                    console.log(orderplaced);
                    await addPurchase_course(orderplaced, token);
                    await paypalInte(order, token);
                    await addtransection(order, orderplaced, token);
                    // alert("payment successfully done");
                    setCongrats(1);
                    // sendDataToParent("1");
                    // console.log(sendDataToParent)
                    // history.push(`/purchase_course/${user_Id}`);
                    // history.push(`/congrats`);



                },
                onError: (err) => {
                    console.log(err);
                },
            })
            .render(paypal.current);
    }, []);

    return (
        <div>
            <div ref={paypal}></div>
            {congrats ?
                <div className="paystyle">
                    <Link to={ `/purchase_course/${user_Id}`}><img src={imgcongo} className="img-fluid  imgpaycongo " alt="successfully done"></img></Link>
                </div>

                :

                <div>process for payment....</div>}
        </div>
    );
}