import React, { useEffect, useLayoutEffect, useState, useReducer } from "react";
import { Col, Button, Input } from "reactstrap";
import { useHistory } from "react-router";
import Navbar from "../navbar";
import './index.css'
import { toaster } from "../../redux/sagas/utils";
import { useDispatch, useSelector } from "react-redux";
import { editSettingsInitiate } from "../../redux/actionCreators/settingsActions";
import { enhancedReducer, isObjEmpty } from "../../redux/sagas/utils";
import { editContactUsInitiate } from "../../redux/actionCreators/contactUSActions";
const initialState = {
  formData: {
    _id: '',
    email: '',
    CountryCode: '',
    contactNo: '',
    address: ''
  }
}

const ContactusDisplay = (props) => {

  const [state, updateState] = useReducer(enhancedReducer, initialState)
  const dispatch = useDispatch()
  
  const history = useHistory()

  const {
    email,
    _id,
    contactNo,
    CountryCode,
    address,
  } = state.formData
  
  const handleSubmit = () => {
    const editable = {
      _id: _id,
      email: email,
      contactNo: contactNo,
      CountryCode: CountryCode,
      address: address
    }
    console.log(editable)
    dispatch(editContactUsInitiate(editable))
  }

  const validate = (e) => {
    e.preventDefault()
    const {_id,email,address,contactNo,CountryCode} = state.formData
    if(!_id.trim().length || !address.trim().length || !email.trim().length || !CountryCode.trim().length || !contactNo.trim().length){
      toaster('warning','Some Fields are missing')
      return
    }
    handleSubmit()
  }
  const handleback = () => {
    history.goBack()
  }
  useLayoutEffect(() => {
    if (!isObjEmpty(props.userInfo) && props.userInfo.length > 0 ) {
      const data = {
        _id: props.userInfo[0]._id,
        contactNo: props.userInfo[0].contactNo,
        email: props.userInfo[0].email,
        CountryCode: props.userInfo[0].CountryCode,
        address: props.userInfo[0].address,
      }
      updateState({
        path: 'formData',
        value: data
      })
    }
  }, [props.userInfo])

  const handleChange = (event, data = null) => {
    const name = data ? data.name : event.target.name
    const value = data ? data.value : event.target.value

    updateState({
      path: `formData.${name}`,
      value: value
    })
  }

  console.log(props.userInfo)
  return(
    <div>
      <form onSubmit={validate} className="form_class">
        <div className="header_container">
          <h2 className="form_header" style={{backgroundColor: 'transparent'}}>Contact us Form</h2>
          <Button onClick={handleback}>Back</Button>
        </div>
        <div className="body_container">
          <div className="row">
            <Col><label>Address</label></Col>
            <Col><Input name="address" type="text" value={address} onChange={handleChange} /></Col>
          </div>
          <div className="row">
            <Col><label>Email</label></Col>
            <Col>
            <Input name="email" type="text" value={email} onChange={handleChange} />
            </Col>
          </div>
          <div className="row">
            <Col><label>Country Code</label></Col>
            <Col><Input name="CountryCode" type="text" value={CountryCode} onChange={handleChange}/></Col>
          </div>
          <div className="row">
            <Col><label>Contact No</label></Col>
            <Col><Input name="contactNo" type="text" value={contactNo} onChange={handleChange}/></Col>
          </div>
          <div className="button-container">
            <Button type="submit" color="success">Update Contact Us</Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactusDisplay