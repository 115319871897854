import { contactUSAction } from "../actionTypes";

const intitalState = {
  contactUSData : [],
};

export const contactUsReducer = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {
    case contactUSAction.FETCH_CONTACT_US_INITIATE: {
      return {
        ...state,
      };
    }
    case contactUSAction.FETCH_CONTACT_US_SUCCESS:{
      console.log(payload)
      return {
        ...state,
        contactUSData: [payload.userList],
      };
    }
    case contactUSAction.EDIT_CONTACT_US_INITIATE: {
      return {
        ...state,
      };
    }
    case contactUSAction.EDIT_CONTACT_US_SUCCESS:{
      const res = state.userList.filter((itm) => itm._id !== payload.userList._id)
      const newres = [...res,payload.userList]
      return {
        ...state, userList: newres
      }
    }
    default:
      return {
        ...state,
      };
  }
};
