import { useEffect, useState, React } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import $ from 'jquery';


// imported component
import "./VIew_update_profile.css";
// import group from "../../Utils/login-left.png";
import { updateProfile, getPost, mediaurl } from "../../services/api";
import axios from "axios";
// imported icon
import { FiUser } from "react-icons/fi";
import { SiMaildotru, SiTeradata } from "react-icons/si";
import { BsTelephone } from "react-icons/bs";
import { BiLockAlt, BiLockOpenAlt } from "react-icons/bi";
import Navbar from "../Navbar/Navbar";
import profile_img from "../../Utils/user.png";

// export const mediaurl = 'http://localhost:3000/';

function VIew_update_profile() {
    const history = useHistory();
    let { id } = useParams();
    // console.log(id);   
    // const [post, setPost] = useState(intialData);
    const [setuser, setSetuser] = useState();
    const [olddata, setOlddata] = useState();


    const [userform, setuserform] = useState({
        firstName: null,
        lastName: null,
        email: null,
        contactNo: null,
        password: null,
        cpassword:null,
        userType: "user",
        loginType: "none",
        deviceType: "none",
        deviceToken: "n123123213asdas",
        countryCode: "12",
        userName: "asdfjsdof",
        profileImage: null
    });

    const [img, setImg] = useState("");
    const [token, setToken] = useState();

    useEffect(() => {
        const fetchData = async () => {
            var data = JSON.parse(localStorage.getItem('userInfo'));
            if (data) {
                var data1 = data.data.data;
                let token = data1.token;
                // console.log(data1);
                setToken(token);
                const fetchDataemail = async () => {
                    let data = await getPost(token);
                    if (data) {

                        setSetuser(data.data.data);
                        setOlddata(data.data.data)
                        // let imggg = ;
                        setImg(mediaurl + data.data.data.profileImage);
                        // console.log(imggg);
                    }
                };
                fetchDataemail();
                // console.log(setuser);
            }
        }
        fetchData();
    }, [])
    // console.log(token);

    const onValueChange = (e) => {
        setSetuser(null);
        // setImg(null)
        setuserform({
            ...userform,
            [e.target.name]: e.target.value
        })

    };
    const handleimagechange = (e) => {
        if (e.target.name === 'image') {
            // console.log(e.target.files[0])
            setuserform({
                ...userform,
                image: e.target.files[0]
            })
            return
        }
    }
    // &&userform.firstName&&userform.lastName&&userform.contactNo&&userform.emai
    // && userform.img&&userform.image.name
    const toHomeDetails = async (e) => {
        e.preventDefault();
        // console.log(userform.password)
        if (userform.password == userform.cpassword ) {
            // console.log(userform.profileImage);  
            const response = await updateProfile(id, token, userform, olddata);
            // console.log(response);
            if (response) {
                history.push("/");
            } else {
                alert("profile not updated");
            }
        }else{
            alert("fill form completely or update data")
        }
    }
    function readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#imagePreview').css('background-image', 'url(' + e.target.result + ')');
                $('#imagePreview').hide();
                $('#imagePreview').fadeIn(650);
                setImg(e.target.result);
            }
            reader.readAsDataURL(input.files[0]);
        };
    }
    $("#imageUpload").change(function () {
        readURL(this);
    });
    // if (setuser) {
    //     console.log(setuser.firstName);
    // } else { console.log(userform.firstName) }

    return (
        <>
            <section className="container-fluid">
                <Navbar />
                <div className="row Registerstyle">

                    <div className="viewcontainer">
                        <div className="bodybg">
                            <div class="container">
                                {/* <img src={img}/> */}
                                {/*{console.log(setuser ? setuser.profileImage : '', mediaurl, "imagefor")} */}
                                {/* <img src={setuser ? mediaurl + setuser.profileImage : ""} /> */}

                                <div class="avatar-upload">
                                    <div class="avatar-edit">
                                        <input type='file'
                                            // value={userform.profileImage}
                                            value={userform.profileImage}
                                            id="imageUpload" accept=".png, .jpg, .jpeg" onChange={(e) => handleimagechange(e)} name="image" alt="upload image" />
                                        <label for="imageUpload"></label>
                                    </div>
                                    <div class="avatar-preview">
                                        <div id="imagePreview"
                                            style={{ backgroundImage: `url(${img})` }}>
                                            {/* <img src={img} /> */}
                                            {/* {console.log(img)} */}
                                        </div>

                                    </div>
                                    {/* {img?<img class="avatar-preview" id="imagePreview" src={img} />:<></>} */}
                                    {/* <figure class="avatar-preview" id="imagePreview" src={img} /> */}
                                </div>
                            </div>
                            {/* {message&&<h1>{message}</h1>} */}
                            {/* register form */}
                            <form>
                                <div className="viewformcontainer">
                                    <div className="viewlast-input me-4  ">
                                        <i > <FiUser /> </i>

                                        <input type="text" name="firstName"
                                            value={setuser ? setuser.firstName : userform.firstName}
                                            placeholder="First Name" onChange={(e) => onValueChange(e)} />
                                    </div>
                                    <div className="viewlast-input me-4 ">
                                        <i > <FiUser /> </i>

                                        {/* <input type="text" name="lastName"
                                            value={setuser ? setuser.lastName : userform.lastName}
                                            placeholder="Last Name" onChange={(e) => onValueChange(e)} /> */}
                                        <input type="text" name="lastName"
                                            value={setuser ? setuser.lastName : userform.lastName}
                                            placeholder="last Name" onChange={(e) => onValueChange(e)} />
                                    </div>
                                    <div className="viewlast-input me-4 ">
                                        <i > <SiMaildotru /> </i>

                                        <input type="email" name="email"
                                            value={setuser ? setuser.email : userform.email}
                                            placeholder="Email" onChange={(e) => onValueChange(e)} />
                                    </div>
                                    <div className="viewlast-input me-4 ">
                                        <i > <BsTelephone /> </i>

                                        <input type="text" name="contactNo"
                                            value={setuser ? setuser.contactNo : userform.contactNo}
                                            placeholder="contact" onChange={(e) => onValueChange(e)} />
                                    </div>
                                    <div className="viewlast-input me-4 ">
                                        <i > <BiLockOpenAlt /> </i>

                                        <input type="password" name="password"
                                            value={setuser ? setuser.password : userform.password} placeholder="Password" onChange={(e) => onValueChange(e)} />
                                    </div>
                                    <div className="viewlast-input me-4 ">
                                        <i > <BiLockAlt /> </i>

                                        <input type="password" name="cpassword"
                                            value={setuser ? setuser.password : userform.cpassword}
                                            placeholder="Confirm Password" onChange={(e) => onValueChange(e)} />
                                    </div>
                                    <div className="viewlastsubmit-input buttonstyle ">
                                        <input type="submit" name="submit" value="Edit Profile" onClick={toHomeDetails} />
                                    </div>
                                    {/* <div className="lastsubmit1-input ">
                                        <input type="submit" name="submit" value="Register" onClick={toLoginPage} />
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default VIew_update_profile;




