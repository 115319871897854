// import React from 'react';
import "./courses.css"
import Card from '../home/Course_card';
import Contactform from '../Contactus/contactform';
import Footer from '../footer/footer';
import Navbar from '../Navbar/Navbar';
import { useEffect, useState, React } from "react";
import { getAllposts, getStaticContent } from "../../services/api";
import { Link } from "react-router-dom";
import time from "../../Utils/Group 510859.png";
import SKILL from "../../Utils/Group 511023.png";
import LECTURES from "../../Utils/Group 511024.png";
import ENROLLED from "../../Utils/Group 511025.png";


function Course() {
  const [posts, setPosts] = useState([]);
  const [course_poster_1, setCourse_poster_1] = useState([]);
  const [course_courses_text, setCourse_courses_text] = useState([]);


  // const { search } = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      const response = await getAllposts();
      const response_course_poster_1 = await getStaticContent("course_poster_1");
      const response_course_courses_text = await getStaticContent("course_courses_text");

      console.log(response_course_courses_text);
      if (response) {
        setPosts(response.data.data);
      }
      if (response_course_poster_1) {
        setCourse_poster_1(response_course_poster_1.data.message[0]);
      }
      if (response_course_courses_text) {
        setCourse_courses_text(response_course_courses_text.data.message[0]);
      }
    }
    fetchData();
  }, []);
  // console.log(course_courses_text);



  return (
    <>


      <div className='container-fluid'>

        <Navbar />
        <div className="banner1">
          <div className="banner">
            <h1 className="head">{course_poster_1 && course_poster_1.title || "EDUCATION DONE DIFFERENTLY."}</h1>
            <p className="para">{course_poster_1 && course_poster_1.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever."}</p>
            {/* <button type="button" class="btn btn-lg btn-warning text-light fw-bold buttonelementstyle">Warning</button> */}
          </div>
        </div>

        <section >
          <div className="container-fluid ">
            <div className="row Detaillinecont">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 ">
                <div className="Detailheads">
                  <img src={time}></img>
                  <div className="Detailtexta">
                    <h1 className="Detailtexth">Duration</h1>
                    <p className="Detailtextp">10 week</p>
                  </div>
                  <div className="Detailline"></div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="Detailheads">
                  <img src={SKILL}></img>
                  <div className="Detailtexta">
                    <h1 className="Detailtexth " >Skill Level</h1>
                    <p className="Detailtextp">Intermediate</p>
                  </div>
                  <div className="Detailline"></div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="Detailheads">
                  <img src={LECTURES}></img>
                  <div className="Detailtexta">
                    <h1 className="Detailtexth" >LECTURES</h1>
                    <p className="Detailtextp">6 lessons    </p>
                  </div>
                  <div className="Detailline"></div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6  col-xl-6 col-xxl-3">
                <div className="Detailheads">
                  <img src={ENROLLED}></img>
                  <div className="Detailtexta">
                    <h1 className="Detailtexth">ENROLLED</h1>
                    <p className="Detailtextp">20 students  </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
      <section className="seccourses">
        <div className='course'>
          <h1 className='courseh' >{course_courses_text && course_courses_text.title || "Courses"}</h1>

          <p className='coursep'>{course_courses_text && course_courses_text.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br />Lorem Ipsum has been the industry's standard dummy."}</p>
          <div className="apptistcontainer">
            <p className='text-end '>ViewAll</p>
            <div className="container-fluid">
              <div className="row">
                {posts.map(post => (
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                    <Link to={`/detailcourse/${post._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                      <Card post={post} />
                    </Link>
                  </div>
                ))
                }

              </div>
            </div>
          </div>
        </div>
      </section>

      <Contactform />
      <Footer />





    </>
  );
}

export default Course;




