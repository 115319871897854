import { userActions } from "../actionTypes";

const intitalState = {
  userList : [],
  courseofUsers : []
  // vehicleCategorySearchList: [],
};

export const userReducer = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {
    case userActions.GET_ALL_USERS_INITIATE: {
      return {
        ...state,
      };
    }
    case userActions.GET_ALL_USERS_SUCCESS:{
      return {
        ...state,
        userList: payload.userList,
      };
    }
    case userActions.GET_COURSES_BY_USERS_INITIATE: {
      return {
        ...state,
      };
    }
    case userActions.GET_COURSES_BY_USERS_SUCCESS:{
      console.log(payload,"ddddd")
      return {
        ...state,
        courseofUsers: payload.userCourses,
      };
    }
    case userActions.CLEAR_COURSES_BY_USERS:{
      return{
        ...state,
        courseofUsers: []
      }
    }
    case userActions.SET_USER_INITIATE: {
      return {
        ...state,
      };
    }
    case userActions.SET_USER_SUCCESS:{
      const res = state.userList.filter((itm) => itm._id !== payload.userList._id)
      const newres = [...res,payload.userList]
      return {
        ...state, userList: newres
      }
    }
    default:
      return {
        ...state,
      };
  }
};
