import { useEffect, useState, React } from "react";
// css
import "./home.css";
import { FaFacebook, FaFacebookF, FaTwitter, FaLinkedinIn, FaRegCalendarAlt, FaChevronRight, FaGooglePlusG, FaPinterestP } from "react-icons/fa";
// imported component
import { getAllposts, getStaticContent } from "../../services/api";
import Banner from "../../Utils/Banner@2x.png";
import Mask from "../../Utils/Mask Group 1@2x.png";
import Card from './Course_card';
import Card1 from '../../Utils/Group 11028@2x.png';
import Card2 from '../../Utils/Group 11029@2x.png';
import groups1 from '../../Utils/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg';
import Footer from '../footer/footer';
import Contactform from '../Contactus/contactform';
import Navbar from '../Navbar/Navbar';
import { Link } from "react-router-dom";


function Home() {
    const [posts, setPosts] = useState([]);
    const [poster_1, setPoster_1] = useState([]);
    const [slide_1_1, setSlide_1_1] = useState([]);
    const [slide_1_2, setSlide_1_2] = useState([]);
    const [slide_1_3, setSlide_1_3] = useState([]);
    const [card_1, setCard_1] = useState([]);
    const [popular_text, setPopular_text] = useState([]);
    const [card_2_1, setCard_2_1] = useState([]);
    const [card_2_2, setCard_2_2] = useState([]);
    const [team_text, setTeam_text] = useState(null);
    const [team_Mdetail_1, setTeam_Mdetail_1] = useState(null);
    const [team_Mdetail_2, setTeam_Mdetail_2] = useState(null);
    const [team_Mdetail_3, setTeam_Mdetail_3] = useState(null);
    const [team_Mdetail_4, setTeam_Mdetail_4] = useState(null);
    const [slide_2_1, setSlide_2_1] = useState(null);
    const [slide_2_2, setSlide_2_2] = useState(null);
    const [slide_2_3, setSlide_2_3] = useState(null);
    const [slide_2_4, setSlide_2_4] = useState(null);
    // const { search } = useLocation();
    useEffect(() => {
        const fetchData = async () => {
            const response = await getAllposts();
            const response_poster_1 = await getStaticContent("home_poster_1");
            const response_slide_1 = await getStaticContent("home_slide_1");
            const response_card_1 = await getStaticContent("home_card_1");
            const response_popular_text = await getStaticContent("home_popular_text");
            const response_card_2_1 = await getStaticContent("home_card_2_1");
            const response_card_2_2 = await getStaticContent("home_card_2_2");
            const response_team_text = await getStaticContent("home_team_text");
            const response_team_Mdetail = await getStaticContent("home_team_Mdetail");
            const response_slide_2 = await getStaticContent("home_slide_2");
            // console.log(response_slide_2)
            if (response) {
                setPosts(response.data.data);
            }
            if (response_poster_1) {
                setPoster_1(response_poster_1.data.message[0]);
            }
            if (response_card_1) {
                setCard_1(response_card_1.data.message);
            }
            if (response_slide_1) {
                setSlide_1_1(response_slide_1.data.message[0]);
                setSlide_1_2(response_slide_1.data.message[1]);
                setSlide_1_3(response_slide_1.data.message[2]);
            }
            if (response_popular_text) {
                setPopular_text(response_popular_text.data.message[0]);
            }
            if (response_card_2_1) {
                setCard_2_1(response_card_2_1.data.message[0]);
            }
            if (response_card_2_2) {
                setCard_2_2(response_card_2_2.data.message[0]);
            }
            if (response_team_text) {
                setTeam_text(response_team_text.data.message[0]);
            }
            if (response_team_Mdetail) {
                setTeam_Mdetail_1(response_team_Mdetail.data.message[0]);
                setTeam_Mdetail_2(response_team_Mdetail.data.message[1]);
                setTeam_Mdetail_3(response_team_Mdetail.data.message[2]);
                setTeam_Mdetail_4(response_team_Mdetail.data.message[3]);
            }
            if (response_slide_2) {
                setSlide_2_1(response_slide_2.data.message[0]);
                setSlide_2_2(response_slide_2.data.message[1]);
                setSlide_2_3(response_slide_2.data.message[2]);
                setSlide_2_4(response_slide_2.data.message[3]);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Navbar />
            <div className="container-fluid">
                <div className="row">
                    <div className=" col-md-4 col-lg-4 p-0 forimgstyle ">
                        <div className="test-new">
                            <img src={Banner} className="img-fluid" alt="..."></img>
                        </div>
                        <div className="position-absolute top-0 start-0 label">
                            <h1 >{poster_1 && poster_1.title || "Modern Teaching"}</h1>
                        </div>
                    </div>
                    <div className=" col-md-8 col-lg-8 p-0">
                        {/* carousel */}
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators carbut">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                {/* {slide_1.map((data, index) => (
                                    <div className={`carousel-item active bgc-${index + 1}`}>
                                        <img src="https://source.unsplash.com/1285x1000/?yellowcolor" className="d-block w-100" alt="..." />
                                        <div class="carousel-caption  d-md-block carbuts ">
                                            <h5>{data.title}</h5>
                                            <p>{data.description}</p>
                                            <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button>
                                        </div>
                                    </div>
                                ))} */}

                                <div className="carousel-item active bgc-1">
                                    {/* <img src="https://source.unsplash.com/1285x1000/?yellowcolor" className="d-block w-100" alt="..." /> */}
                                    <div class="carousel-caption  d-md-block carbuts ">
                                        <h5>{slide_1_1 && slide_1_1.title || "Modern learning"}</h5>
                                        <p>{slide_1_1 && slide_1_1.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever."}</p>
                                        {/* <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button> */}

                                    </div>
                                </div>
                                <div className="carousel-item bgc-2">
                                    {/* <img src="https://source.unsplash.com/user/erondu/1285x1000" className="d-block w-100" alt="..." /> */}
                                    <div class="carousel-caption  d-md-block carbuts ">
                                        <h5>{slide_1_2 && slide_1_2.title || "Modern learning"}</h5>
                                        <p>{slide_1_2 && slide_1_2.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever."}</p>
                                        {/* <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button> */}

                                    </div>
                                </div>
                                <div className="carousel-item bgc-3">
                                    {/* <img src="https://source.unsplash.com/user/erondu/1285x1000" className="d-block w-100" alt="..." /> */}
                                    <div class="carousel-caption  d-md-block carbuts ">
                                        <h5>{slide_1_3 && slide_1_3.title || "Modern learning"}</h5>
                                        <p>{slide_1_3 && slide_1_3.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever."}</p>
                                        {/* <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button> */}
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>

                {/* 3 cards */}
                <div >
                    <div className="row">
                        {card_1.map((data, index) => (

                            (index ? <div className="col-md-4 col-lg-4 p-0 position-relative backg1" >
                                {/* <img src={Mask} className="img-fluid" alt="..."></img> */}
                                <div className="position-absolute top-0 start-0 cardtextss">
                                    <h5>{data.title}</h5>
                                    <p>{data.description} </p>
                                    {/* <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button> */}
                                </div>
                            </div> : <div className="col-md-4 col-lg-4 p-0 position-relative backgg" >
                                {/* <img src={Mask} className="img-fluid" alt="..."></img> */}
                                <div className="position-absolute top-0 start-0 cardtextss">
                                    <h5>{data.title}</h5>
                                    <p>{data.description} </p>
                                    {/* <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button> */}
                                </div>
                            </div>)

                        ))}

                        {/* <div className="col-md-4 col-lg-4 p-0 position-relative backgg" >
                            <img src={Mask} className="img-fluid" alt="..."></img>
                            <div className="position-absolute top-0 start-0 cardtextss">
                                <h5>Lorem ipsum is simply</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 p-0 position-relative backg1" >
                            <img src={Mask} className="img-fluid" alt="..."></img>
                            <div className="position-absolute top-0 start-0 cardtextss">
                                <h5>Lorem ipsum is simply</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been </p>
                                <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button>
                            </div>
                        </div> */}
                        <div className="col-md-4 col-lg-4 p-0 position-relative" >
                            <img src={Mask} className="img-fluid" alt="..."></img>
                        </div>
                    </div>
                </div>
            </div>

            {/* our courses */}
            <section className="seccourses">
                <div className="text-center textcourse ">
                    <h1 >
                        {popular_text && popular_text.title || "popular courses"}
                    </h1>
                    <p >
                        {popular_text && popular_text.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#x27;s standard dummy text ever."}

                    </p>
                </div>
                <div className="apptistcontainer">
                    <p className='text-end  viewtext'>ViewAll</p>
                    <div className="row">
                        {posts.map(post => (
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                {/* <Card post={post} /> */}
                                <Link to={`/detailcourse/${post._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                                    <Card post={post} />
                                </Link>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </section>

            {/* banner */}
            <div className="banner1">
            </div>
            {/* cards 2 */}
            <section >
                <div className="row  card2 cardpara">
                    <div className="col-sm-12 col-md-6 col-lg-6 card2text">
                        <h1 >{card_2_1 && card_2_1.title || "enter card_2-1 title"}</h1>
                        <p>{card_2_1 && card_2_1.description || "enter card_2-1 Description"}</p>
                        {/* <button type="button" class="btn btn-dark ">Dark</button> */}
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <img src={Card1} className="img-fluid" alt="..."></img>
                    </div>
                </div>
                <div className="row  card2 cardpara ">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <img src={Card2} className="img-fluid" alt="..."></img>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 card2text1">
                        <h1 >{card_2_2 && card_2_2.title || "enter card_2-1 title"}</h1>
                        <p>{card_2_2 && card_2_2.description || "enter card_2-1 Description"}</p>
                        {/* <button type="button" class="btn btn-dark ">Dark</button> */}
                    </div>
                </div>
            </section>

            {/* carousle2 */}

            <section>
                <div id="carouselExampleCaptions" className="carousel slide carosel2m" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="https://source.unsplash.com/user/erondu/1285x450" className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 className="carosel2mh1">{slide_2_1 && slide_2_1.title || "First slide label"}</h5>
                                <p>{slide_2_1 && slide_2_1.description || "Some representative placeholder content for the first slide."}</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="https://source.unsplash.com/user/erondu/1285x450" className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 className="carosel2mh1">{slide_2_2 && slide_2_2.title || "Second slide label"}</h5>
                                <p>{slide_2_2 && slide_2_2.description || "Some representative placeholder content for the second slide."}</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="https://source.unsplash.com/user/erondu/1285x450" className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 className="carosel2mh1">{slide_2_3 && slide_2_3.title || "Third slide label"}</h5>
                                <p>{slide_2_3 && slide_2_3.description || "Some representative placeholder content for the third slide."}</p>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>

            {/* our teams */}
            <section>
                <div className="text-center p-4 m-4">
                    <h1 className="fw-bold teamstyleh1">
                        {team_text && team_text.title || "Meet Our Teams"}
                    </h1>
                    <p className="teamstylep" >
                        {team_text && team_text.description || " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."}
                    </p>
                </div>
                <div className="apptistcontainer">
                    <div className='row'>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 ">
                            <div className="team">
                                <h1 className=" teamh" >{team_Mdetail_1 && team_Mdetail_1.title || "Johnson (CEO)"}</h1>
                                <p className=" teamp">{team_Mdetail_1 && team_Mdetail_1.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                                <div className='linelogos'>
                                    <FaFacebook className="facebook" />
                                    <FaTwitter className="twitter" />
                                    <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 ">
                            <div className="team">
                                <h1 className=" teamh" >{team_Mdetail_2 && team_Mdetail_2.title || "Johnson (CEO)"}</h1>
                                <p className=" teamp">{team_Mdetail_2 && team_Mdetail_2.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                                <div className='linelogos'>
                                    <FaFacebook className="facebook" />
                                    <FaTwitter className="twitter" />
                                    <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 ">
                            <div className="team">

                                <h1 className=" teamh" >{team_Mdetail_3 && team_Mdetail_3.title || "Johnson (CEO)"}</h1>
                                <p className=" teamp">{team_Mdetail_3 && team_Mdetail_3.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                                <div className='linelogos'>
                                    <FaFacebook className="facebook" />
                                    <FaTwitter className="twitter" />
                                    <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 ">
                            <div className="team">

                                <h1 className=" teamh" >{team_Mdetail_4 && team_Mdetail_4.title || "Johnson (CEO)"}</h1>
                                <p className=" teamp">{team_Mdetail_4 && team_Mdetail_4.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                                <div className='linelogos'>
                                    <FaFacebook className="facebook" />
                                    <FaTwitter className="twitter" />
                                    <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* blog &ceo */}
            <section className="apptistcontainer sec">
                <div className="row apptistcontainer ">
                    <div className="col-sm-12 col-md-2 col-lg-3 blog">
                        <h1 className="fw-bold blogh"> Blog </h1>
                        <p>Lorem Ipsum is simply dummy text of the printing and type set industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when unknown printer took a galley of type and scram it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.

                            <br />
                            <br />
                            <br />
                            <br />

                            Lorem Ipsum is simply dummy text of the printing and type set industry.
                            <br />
                            <br />
                            <br />
                            <br />

                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when unknown printer took a galley of type and scram it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                    </div>

                    <div className="col-sm-12 col-md-10 col-lg-9 ">
                        <div className="row">
                            <div className="col-md-12 col-lg-6 cardblog">
                                <h1 className="blogcardh">John Leo (CEO)</h1>
                                <p className="blogcardp"><span className="m-2"><FaRegCalendarAlt /></span>20 Sep 2021</p>
                                <img src={groups1} className="img-fluid" ></img>
                                <h1 className="mt-4">lorem sdjflksadf asdfjlsdf   </h1>
                                <p className="mt-4">Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button>
                                <div className='line2'>
                                    <FaFacebookF className="facebookicon" />
                                    <FaTwitter className="facebookicon" />
                                    <FaGooglePlusG className="facebookicon" />
                                    <FaLinkedinIn className="facebookicon" />
                                    <FaPinterestP className="facebookicon" />
                                    <div className="test-line"></div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 cardblog">
                                <h1 className="blogcardh">John Leo (CEO)</h1>
                                <p className="blogcardp"><span className="m-2"><FaRegCalendarAlt /></span>20 Sep 2021</p>
                                <img src={groups1} className="img-fluid" ></img>
                                <h1 className="mt-4">lorem sdjflksadf asdfjlsdf   </h1>
                                <p className="mt-4">Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button>
                                <div className='line2'>
                                    <FaFacebookF className="facebookicon" />
                                    <FaTwitter className="facebookicon" />
                                    <FaGooglePlusG className="facebookicon" />
                                    <FaLinkedinIn className="facebookicon" />
                                    <FaPinterestP className="facebookicon" />
                                    <div className="test-line"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-6 cardblog">
                                <h1 className="blogcardh">John Leo (CEO)</h1>
                                <p className="blogcardp"><span className="m-2"><FaRegCalendarAlt /></span>20 Sep 2021</p>
                                <img src={groups1} className="img-fluid" ></img>
                                <h1 className="mt-4">lorem sdjflksadf asdfjlsdf   </h1>
                                <p className="mt-4">Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button>
                                <div className='line2'>
                                    <FaFacebookF className="facebookicon" />
                                    <FaTwitter className="facebookicon" />
                                    <FaGooglePlusG className="facebookicon" />
                                    <FaLinkedinIn className="facebookicon" />
                                    <FaPinterestP className="facebookicon" />
                                    <div className="test-line"></div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 cardblog">
                                <h1 className="blogcardh">John Leo (CEO)</h1>
                                <p className="blogcardp"><span className="m-2"><FaRegCalendarAlt /></span>20 Sep 2021</p>
                                <img src={groups1} className="img-fluid" ></img>
                                <h1 className="mt-4">lorem sdjflksadf asdfjlsdf   </h1>
                                <p className="mt-4">Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                <button type="button" class="btn btn-dark mt-4 "> lorem ipsum <FaChevronRight /></button>
                                <div className='line2'>
                                    <FaFacebookF className="facebookicon" />
                                    <FaTwitter className="facebookicon" />
                                    <FaGooglePlusG className="facebookicon" />
                                    <FaLinkedinIn className="facebookicon" />
                                    <FaPinterestP className="facebookicon" />
                                    <div className="test-line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* contactUs*/}
            <Contactform />
            <Footer />
        </>

    )
}

export default Home;
