import './index.css'
import { ReactComponent as PurchasedCourse } from '../../assets/PurchasedCourse.svg';
import { ReactComponent as Skilllevel } from '../../assets/Skilllevel.svg';
import { ReactComponent as TotalDuration } from '../../assets/TotalDuration.svg';
import { ReactComponent as TotalStudents } from '../../assets/TotalStudents.svg';



import { ReactComponent as Bell } from '../../assets/Bell.svg';
import StudentTable from '../Home/studenttables';
import CourseList from '../Home/courseList';
import SecondHeader from '../SecondHeader';
import Cards from './topcards';
import { useEffect } from 'react';
import { fetchCourseInitiate } from '../../redux/actionCreators/courseActions';
import { useDispatch, useSelector,batch } from 'react-redux';
import { fetchstaticPageInitiate } from '../../redux/actionCreators/staticPageActions';
import Footer from '../Footer';

const CustomDashboard = ({show}) => {


  console.log(useSelector(state => state))
  const width = show ? '325px' : '84px'
  return(
    <div className="main" style={{marginLeft: width}}>
      <div>
        <SecondHeader />
        <section>
          <Cards />  
        </section>
        <section>
          <StudentTable />
        </section>
        <section>
          <CourseList />
        </section>
        <section>
          <Footer />
        </section>
      </div>
    </div>
  )
}
export default CustomDashboard