import { useState, React, createContext } from "react";
// import { } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// import Navbar from "../Navbar/Navbar";


// imported component
import "./login.css";
import group from "../../Utils/login-left.png";
import { userLogin } from "../../services/api";
import symbol1 from "../../Utils/logo1.png";
import symbol2 from "../../Utils/logo 2.png";
import symbol3 from "../../Utils/logo3.png";
import symbol4 from "../../Utils/logo4.png";


// imported icon
import { SiMaildotru } from "react-icons/si";
import { RiLock2Line } from "react-icons/ri";

// var LogedinContext = createContext();

const intialData = {
    password: "",
    email: ""

}

function Login() {
    // const dispatch = useDispatch();

    const history = useHistory();
    const [data, setData] = useState(intialData);
    const [logedin, setLogedin] = useState(false);


    // const userLogin = useSelector((state) => state.userLogin);

    const { password, email } = data;


    const onValueChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        // console.log(data)
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        // await toLogin(data)
        try {
            // dispatch({ type: USER_LOGIN_REQUEST });
            // const config = {
            //     headers: {
            //         "Content-type": "application/json",
            //     },
            // };
            // console.log(data)
            // const response = await axios.post(`http://localhost:3000/api/auth/login`, data, config);
            const response = await userLogin(data);
            if (response) {
                // console.log(response.data);
                localStorage.setItem("userInfo", JSON.stringify(response));
            }
            function logout() {
                // var x=1
                setTimeout(() => {
                    localStorage.removeItem('userInfo');
                    history.push("/signup");
                    window.location.reload(false);

                }, 720000);

            };
            logout();
            setLogedin(true)
            // console.log(response)
            history.push("/")
            window.location.reload(false);

            // localStorage.setItem('document',JSON.stringify(this.state));

        } catch (error) {
            console.log("error in login", error);
            function displayResult() {
                document.getElementById("myHeader").innerHTML = "wrong password";
            }
            displayResult();
        }


    }
    const toregisterDetails = () => {
        history.push("/register")

    }



    return (
        <>
            {/* <LogedinContext.Provider value={logedin}>
                <Navbar />
            </LogedinContext.Provider> */}

            <section>
                <div className="row style">
                    <div className="col-lg-6 col-xl-6 col-xxl-6 col-md-6 col-sm-12">
                        <img className="img-fluid imgstyle" src={group} alt=".." />
                    </div>
                    <div className="col-lg-6 col-xl-6 col-xxl-6 col-md-6 col-sm-12 formcontainer">
                        <div className="row">
                            <div className="col">
                                <Link className="nav-link nav-linka  Logincss" to="/signup"><h1>Login</h1></Link>
                                <div className="Loginline"></div>
                            </div>
                            <div className="col">
                                <Link className="nav-link nav-linka  Registercss" to="/register" ><h1>Register</h1></Link>
                                {/* <div className="Loginline123"></div> */}

                            </div>
                        </div>
                        {/* <div class="toast align-items-center" id="myHeader" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="d-flex">
                                <div class="toast-body">
                                    <p id="myHeader" className="text-dark">asaasdsad</p>
                                </div>
                                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                            </div>
                        </div> */}
                        <p id="myHeader" className="toas"></p>

                        <form>
                            <div className="loginformcontainer">

                                <div className="first-input me-4 ">
                                    <i > <SiMaildotru /> </i>

                                    <input type="email" name="email" value={email} placeholder="Email" onChange={onValueChange} />
                                </div>

                                <div className="first-input me-4 ">
                                    <i > <RiLock2Line /> </i>

                                    <input type="password" name="password" value={password} placeholder="Password" onChange={onValueChange} />
                                </div>

                                <div className="firstsubmit-input ">
                                    <input type="submit" name="submit" value="Login" onClick={submitHandler} />
                                </div>
                                <div className="firstsubmit1-input ">
                                    <input type="submit" name="submit" value="Register" onClick={toregisterDetails} />
                                </div>
                                {/* <div className="firstconnectWith">
                                    <p>---- Or connect with ----</p>
                                </div> */}

                            </div>

                        </form>
                        {/* <div className="firstsymbolcont">
                            <div className="firstsymbol">
                                <img src={symbol1} alt="..." />
                            </div>
                            <div className="firstsymbol">
                                <img src={symbol2} alt="..." />
                            </div>
                            <div className="firstsymbol">
                                <img src={symbol3} alt="..." />
                            </div>
                            <div className="firstsymbol">
                                <img src={symbol4} alt="..." />
                            </div>
                        </div> */}


                    </div>


                </div>
            </section>
        </>

    );
}

export default Login;




