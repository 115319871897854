import axios from "axios";

const URL = "http://apptist.ca";
// http://localhost:3000/
// http://localhost:5000/api/course/

// image url
export const mediaurl = 'http://apptist.ca';


// courses api 
export const getAllposts = async () => {
    try {
        let response = await axios.get(`${URL}/api/course/`)
        return response;
    } catch (error) {
        console.log(error);

    };
};

export const getPostById = async (id) => {
    // console.log(id);
    try {
        let response = await axios.get(`${URL}/api/course/courseDetails/${id}`);
        // console.log(id);
        // console.log(response);

        return response;

    } catch (error) {
        console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
    }
}




// upadating data of user login/sigup

export const updatePost = async (id, post) => {
    try {
        await axios.post(`${URL}/api/auth/updateProfile/${id}`, post);
        // console.log(post)

    } catch (e) {
        console.log('error in updating Blog from api', e);

    }

}

// contacus page services
export const addContact = async (user) => {

    try {
        return await axios.post(`${URL}/api/contactUs/addcontact`, user);

    } catch (error) {
        console.log(error);

    };
}






// order api

export const addPurchase_course = async (user, token) => {
    // console.log(token);
    // console.log(user);
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {
        return await axios.post(`${URL}/api/order`, user, config);
    } catch (error) {
        console.log(error);

    };
}
export const getpurchasedcourse = async (token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    // console.log(token);
    try {
        // let response = await axios.get(`${URL}/api/user/course/${id}`);
        let response = await axios.get(`${URL}/api/user/course`, config);

        // console.log(id);
        // console.log(response);

        return response;

    } catch (error) {
        console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
    }
}
// transection api

export const addtransection = async (data, orderplaced, token) => {
    // console.log(token);
    console.log(orderplaced);
    console.log(data);

    const newdata = {
        // order: data,
        // orderplaced: orderplaced
        user: orderplaced.user,
        userId: orderplaced.id,
        amount: orderplaced.amount,
        intent: data.intent,
        status: data.status,
        payerId: data.payer.payer_id,
        userEmail: data.payer.email_address,
        // orderId: req.body.orderId,
        currency: data.payer.address.country_code,
        courseId: orderplaced.courseId,
        paypalId: data.id,
        // payee_email: req.body.payee_email,
        payerEmail: data.payer.email_address,
        // merchent_Id: req.body.merchentId,
        created_time: data.create_time,
        // payerAddress: req.body.payerAddress,
        // updated_time: req.body.updated_time,
    }
    console.log(newdata, "newdata")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {

        return await axios.post(`${URL}/api/transaction`, newdata, config);


    } catch (error) {
        console(error)
    }
}

export const gettransectionById = async (id, token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    // console.log(id);
    try {
        let response = await axios.get(`${URL}/api/transaction/${id}`, config);
        // console.log(id);
        // console.log(response);

        return response;

    } catch (error) {
        console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
    }
}

export const paypalInte = async (data, token) => {
    // console.log(token);

    // console.log(data)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {

        return await axios.post(`${URL}/api/config/paypal`, data, config);


    } catch (error) {
        console(error)
    }
}


// auth api

export const userRegister = async (data) => {
    // console.log(data);
            // dispatch({ type: USER_LOGIN_REQUEST });
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    };
    try {
        return await axios.post(`${URL}/api/auth/register`, data, config);
    } catch (error) {
        console(error)
    }
}


export const userLogin = async (data) => {
    // console.log(data);
            // dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
        headers: {
            "Content-type": "application/json"
        }
    };
    try {
        return await axios.post(`${URL}/api/auth/login`, data, config);
    } catch (error) {
        console(error);
    }
};
export const updateProfile = async (id, token, post,olddata) => {
    console.log(id);
    console.log(token);
    console.log(post, " the final post");
    console.log(post.firstName);
    console.log(olddata);



    const formData = new FormData();
    formData.append('firstName', post.firstName);
    formData.append('lastName', post.lastName);
    formData.append('email', post.email);
    formData.append('contactNo', post.contactNo);
    formData.append('password', post.password);
    // formData.append('cpassword', post.cpassword);
    formData.append('userType', "admin");
    formData.append('deviceType', "iphone");
    formData.append('deviceToken', "n123123213asdas");
    formData.append('countryCode', "546");
    formData.append('userName', post.email);
    if(post.image&&post.image.name){
    formData.append("profileImage", post.image, post.image.name);
    }
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {

        return await axios.post(`${URL}/api/auth/updateProfile/${id}`, formData, config);

    } catch (error) {
        console.log(error);
    }
}
// getting data of user login/sigup
export const getPost = async (token) => {
    console.log(token);
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {
        let response = await axios.get(`${URL}/api/auth/user_profile`, config);
        return response;
    } catch (error) {
        console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
    }
}

// PURCHASE DETAIL COURSE

export const getpurchased_course = async (id) => {
    console.log(id);
  
    try {
        let response = await axios.get(`${URL}/api/course/courseDetails/${id}`);
        return response;
    } catch (error) {
        console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
    }
}


// static api

export const getStaticContent = async (metakeyword) => {
    try {
        let response = await axios.get(`${URL}/api/staticContent/${metakeyword}`)
        return response;
    } catch (error) {
        console.log(error);
    };
};






