import {takeLatest} from "redux-saga/effects"

import {
  editSettingsSaga,
  fetchSettingsSaga
} from "./sagas/settingsSaga"

import {
  loginSaga,
  signupSaga
} from "./sagas/authenticationSaga"

import {
  fetchCourseSage,
  addcourseSaga,
  getcourseDetailsSaga,
  deletecourseSaga,
  editcourseSaga,
  addTransactionSaga,
  generateOrderSaga,
} from "./sagas/fetchSagas"
import {
  addStaticPageSaga,
  editStaticPageSaga,
  fetchStaticPageSaga,
  deletestaticpageSaga,
  fetchtermsOfUseSaga,
  fetchPrivacypolicySaga,
} from "./sagas/staticPagesSaga"

import {
  authentication,
  settingsActions,
  staticPageActions,
  // transactionActions,
  orderActions,
  userActions,
  contactUSAction,
  coursesActions,
  transactionActions,
} from "./actionTypes"
import { fetchAllTransactionsSaga } from "./sagas/transactionSagas"
import { fetchAllOrderssSaga } from "./sagas/orderSagas"
import { getalluserSaga, toggleActivation, getcoursebyUserSaga } from "./sagas/userSagas"
import { editContactUsSaga, getContactUSSaga } from "./sagas/contactUsSaga"


export function* watcher () {
  yield takeLatest(authentication.LOGIN_INIT, loginSaga)
  yield takeLatest(authentication.SIGNUP_INIT, signupSaga)

  yield takeLatest(userActions.GET_ALL_USERS_INITIATE, getalluserSaga)
  yield takeLatest(userActions.SET_USER_INITIATE,toggleActivation)
  yield takeLatest(orderActions.FETCH_ORDER_INITIATE, fetchAllOrderssSaga)

  yield takeLatest(contactUSAction.FETCH_CONTACT_US_INITIATE, getContactUSSaga)
  yield takeLatest(contactUSAction.EDIT_CONTACT_US_INITIATE, editContactUsSaga)

  yield takeLatest(coursesActions.GET_COURSE_INITIATE, fetchCourseSage)
  yield takeLatest(coursesActions.ADD_COURSE_INITIATE, addcourseSaga)

  yield takeLatest(coursesActions.GET_COURSE_DETAILS_INITIATE,getcourseDetailsSaga)
  yield takeLatest(coursesActions.DELETE_COURSE_INITIATE,deletecourseSaga)

  yield takeLatest(staticPageActions.FETCH_TERMS_OF_USE_INITIATE,fetchtermsOfUseSaga)

  yield takeLatest(staticPageActions.FETCH_PRIVACY_POLICY_INITIATE,fetchPrivacypolicySaga)

  yield takeLatest(coursesActions.EDIT_COURSE_INITIATE,editcourseSaga)

  yield takeLatest(userActions.GET_COURSES_BY_USERS_INITIATE,getcoursebyUserSaga)

  yield takeLatest(staticPageActions.ADD_STATIC_PAGE_INITIATE,addStaticPageSaga)
  yield takeLatest(staticPageActions.EDIT_STATIC_PAGE_INITIATE,editStaticPageSaga)
  yield takeLatest(staticPageActions.FETCH_STATIC_PAGE_INITIATE,fetchStaticPageSaga)
  yield takeLatest(staticPageActions.DELETE_STATIC_PAGE_INITIATE,deletestaticpageSaga)

  yield takeLatest(contactUSAction.FETCH_CONTACT_US_INITIATE,getContactUSSaga)
  yield takeLatest(transactionActions.FETCH_TANSACTION_INITIATE,fetchAllTransactionsSaga)
}