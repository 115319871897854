import { transactionActions } from "../actionTypes";

export const fetchTransactionsInitiate = (data) => {
  return {
    type: transactionActions.FETCH_TANSACTION_INITIATE,
    payload: data,
  };
};

export const fetchTransactionsSuccess = (data) => {
  return {
    type: transactionActions.FETCH_TANSACTION_SUCCESS,
    payload: data,
  };
};
