export const authentication = {
  LOGIN_INIT: "LOGIN_INIT",
  
  LOGIN_FAILED: "LOGIN_FAILED",

  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  
  SIGNUP_INIT: "SIGNUP_INIT",
  
  LOGOUT: "LOGOUT",
}

export const coursesActions = {
  GET_COURSE_INITIATE: 'GET_COURSE_INITIATE',
  GET_COURSE_SUCCESS: 'GET_COURSE_SUCCESS',

  ADD_COURSE_INITIATE: 'ADD_COURSE_INITIATE',
  ADD_COURSE_SUCCESS: 'ADD_COURSE_SUCCESS',

  DELETE_COURSE_INITIATE: 'DELETE_COURSE_INITIATE',
  DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',

  EDIT_COURSE_INITIATE: 'EDIT_COURSE_INITIATE',
  EDIT_COURSE_SUCCESS: 'EDIT_COURSE_SUCCESS',

  GET_COURSE_DETAILS_INITIATE: 'GET_COURSE_DETAILS_INITIATE',
  GET_COURSE_DETAILS_SUCCESS: 'GET_COURSE_DETAILS_SUCCESS',

  ADD_TRANSACTION_INITIATE: 'ADD_TRANSACTION_INITIATE',
  ADD_TRANSACTION_SUCCESS: 'ADD_TRANSACTION_SUCCESS',

  GENERATE_ORDER_INITIATE: 'GENERATE_ORDER_INITIATE',
  GENERATE_ORDER_SUCCESS: 'GENERATE_ORDER_SUCCESS',
}

export const userActions = { 
  GET_ALL_USERS_INITIATE: 'GET_ALL_USERS_INITIATE',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',

  GET_COURSES_BY_USERS_INITIATE: 'GET_COURSES_BY_USERS_INITIATE',
  GET_COURSES_BY_USERS_SUCCESS: 'GET_COURSES_BY_USERS_SUCCESS',

  CLEAR_COURSES_BY_USERS: 'CLEAR_COURSES_BY_USER',
  
  SEARCH_USERS_INITIATE: 'SEARCH_USER_INITIATE',
  SEARCH_USERS_SUCCESS: 'SEARCH_USER_SUCCESS',
  
  SET_USER_INITIATE: 'SET_USER_INITIATE',
  SET_USER_SUCCESS: 'SET_USER_SUCCES',
}

export const contactUSAction = {
  FETCH_CONTACT_US_INITIATE: 'FETCH_CONTACT_US_INITIATE',
  FETCH_CONTACT_US_SUCCESS: 'FETCH_CONTACT_US_SUCCESS',
  
  EDIT_CONTACT_US_INITIATE: 'EDIT_CONTACT_US_INITIATE',
  EDIT_CONTACT_US_SUCCESS: 'EDIT_CONTACT_US_SUCCESS',
}

export const transactionActions = {
  FETCH_TANSACTION_INITIATE: 'FETCH_TANSACTION_INITIATE',
  FETCH_TANSACTION_SUCCESS: 'FETCH_TANSACTION_SUCCESS',
}

export const staticPageActions = {
  FETCH_STATIC_PAGE_INITIATE: 'FETCH_STATIC_PAGE_INITIATE',
  FETCH_STATIC_PAGE_SUCCESS: 'FETCH_STATIC_PAGE_SUCCESS',
  
  DELETE_STATIC_PAGE_INITIATE: 'DELETE_STATIC_PAGE_INITIATE',
  DELETE_STATIC_PAGE_SUCCESS: 'DELETE_STATIC_PAGE_SUCCESS',
  
  ADD_STATIC_PAGE_INITIATE: 'ADD_STATIC_PAGE_INITIATE',
  ADD_STATIC_PAGE_SUCCESS: 'ADD_STATIC_PAGE_SUCCESS',
  
  EDIT_STATIC_PAGE_INITIATE: 'EDIT_STATIC_PAGE_INITIATE',
  EDIT_STATIC_PAGE_SUCCESS: 'EDIT_STATIC_PAGE_SUCCESS',

  FETCH_PRIVACY_POLICY_INITIATE: 'FETCH_PRIVACY_POLICY_INITIATE',
  FETCH_PRIVACY_POLICY_SUCCESS: 'FETCH_PRIVACY_POLICY_SUCCESS',
  
  FETCH_TERMS_OF_USE_INITIATE: 'FETCH_TERMS_OF_USE_INITIATE',
  FETCH_TERMS_OF_USE_SUCCESS: 'FETCH_TERMS_OF_USE_SUCCESS'
}

export const settingsActions = {
  FETCH_SETTINGS_INITIATE: 'FETCH_SETTINGS_INITIATE',
  FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS',
  
  EDIT_SETTINGS_INITIATE: 'EDIT_SETTINGS_INITIATE',
  EDIT_SETTINGS_SUCCESS: 'EDIT_SETTINGS_SUCCESS',
}

export const orderActions = {
  FETCH_ORDER_INITIATE: 'FETCH_ORDER_INITIATE',
  FETCH_ORDER_SUCCESS: 'FETCH_ORDER_SUCCESS'
}