import { React, useState, useEffect } from 'react';
import { getStaticContent } from "../../services/api";
import Banner from "../../Utils/Banner@2x.png";
import Navbar from '../Navbar/Navbar';
import "./Contactus.css"
import { FaGooglePlusG, FaFacebookF, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import Contactform from './contactform';
import map from "../../Utils/Mask Group 52@2x.png";
import contactimg from "../../Utils/contact-img.png";
import Footer from '../footer/footer';




function ContactUs() {


    const [contact_poster_text, setContact_poster_text] = useState();
    const [about_card_text, setAbout_card_text] = useState();


    // const { search } = useLocation();
    useEffect(() => {
        const fetchData = async () => {

            const response_contact_poster_text = await getStaticContent("contact_poster_text");
            const response_about_card_text = await getStaticContent("about_card_text");


            if (response_contact_poster_text) {
                setContact_poster_text(response_contact_poster_text.data.message[0]);
            }
            if (response_about_card_text) {
                setAbout_card_text(response_about_card_text.data.message[0]);
            }
        }
        fetchData();
    }, []);

    // console.log(about_card_text);
    return (
        <>
            <Navbar />
            {/* <div>Contact Us</div> */}
            <section className="container-fluid">
                <div className="row">
                    <div className="col-sm-0 col-md-4 col-lg-4 p-0">
                        <div className="test-newnew">
                            <img src={Banner} className="img-fluid" alt="..."></img>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-8 p-0 cardb">

                        {/* carousel */}
                        <div className="carda">
                            <h1 className="cardh">{contact_poster_text && contact_poster_text.title || "Contact Us"}</h1>
                            <p className="cardp">{contact_poster_text && contact_poster_text.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever"}.</p>
                            <div className="last1">
                                <FaFacebookF className="icon12" />
                                <FaTwitter className="icon12" />
                                <FaGooglePlusG className="icon12" />
                                <FaLinkedin className="icon12" />
                                <FaInstagram className="icon12" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <div className="contact">
                <Contactform />
            </div>
            {/* contact form cards */}
            <section>
                <div >
                    <div className="row imgcontainer">

                        <div className="col-md-4 col-lg-4 p-0 position-relative " >
                            {/* <img src={Mask} className="img-fluid" alt="..."></img> */}
                            <img src={map} className="img-fluid" alt="..."></img>


                        </div>
                        <div className="col-md-4 col-lg-4 p-0 position-relative " >
                            {/* <img src={Mask} className="img-fluid" alt="..."></img> */}
                            <img src={contactimg} className="img-fluid" alt="..."></img>

                        </div>
                        <div className="col-md-4 col-lg-4 p-0 position-relative backg" >
                            <div className=" top-0 start-0 cardtext">
                                <h5>Lorem ipsum is simply</h5>
                                {/* <div className="single-input">
                                    <input type="text" name="name" placeholder="NAME" />
                                </div>
                                <div className="single-input">
                                    <input type="email" name="email" placeholder="Email" />
                                </div> */}
                                <p className="companyname">{about_card_text && about_card_text.title || "Apptist"} </p>
                                <p className="companymail">{about_card_text && about_card_text.description || "apptist@mail.com"}</p>
                                {/* <button type="button" class="btn btn-dark mt-4 "> lorem ipsum </button> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />





        </>
    );
}

export default ContactUs;




