import { put } from "redux-saga/effects";
import { addstaticPageSuccess, deletestaticPageSuccess, editstaticPageSuccess, fetchprivacyPolicySuccess, fetchstaticPageSuccess, fetchtermsOfuseSuccess  } from "../actionCreators/staticPageActions";
import { makereq, toaster } from "./utils";
var qs = require('qs');

export function* fetchStaticPageSaga({payload}) {
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('static-pages','get',null,headers)
    const {data} = res
    yield put(fetchstaticPageSuccess({data}))
  } 
  catch (error) {
    console.log(error)
  }
}

export function* addStaticPageSaga({payload}) {
  try {
    console.log("HELLOOEOEOE")
    var data = qs.stringify({
      'title': payload.title,
      'description': payload.description ,
      'metaKeyword':payload.metaKeyword,
      'metaDescription':payload.metaDescription
      });
      const headers= { 
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
      }
      const res = yield makereq('static-pages','post',data,headers)
      const resData = res.data
      yield put(addstaticPageSuccess({resData}))
      toaster('success','Static Page added successfully')
    } 
    catch (error) {
      toaster('error','Error Occured')
      console.log(error)
    }
  }

export function* editStaticPageSaga({payload}) {
  console.log(payload)
  try {
    var form = qs.stringify({
      'title': payload.title,
      'description': payload.description ,
      'metaKeyword':payload.metaKeyword,
      'metaDescription':payload.metaDescription
      });
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`static-pages/${payload.id}`,'put',form,headers)
    console.log(res,"saga")
    const {data} = res
    yield put(editstaticPageSuccess({data}))
    toaster('success',"About-us Updated")
  } 
  catch (error) {
    console.log(error)
  }
}


export function* deletestaticpageSaga({payload}) {
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    yield makereq(`static-pages/${payload}`,'delete',null,headers)
    yield put(deletestaticPageSuccess({payload}))
    toaster('success','Shop category deleted successufully')
  }
  catch (error) {
    toaster('error','Error Occured')
    console.log(error)
  }
}

export function* fetchtermsOfUseSaga({payload}) {
  try {
    console.log("terms of use Sags")
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`static-pages/termsofuse`,'get',null,headers)
    const {data} = res
    yield put(fetchtermsOfuseSuccess({data}))
    toaster('success','terms of use successufully')
  }
  catch (error) {
    toaster('error','Error Occured')
    console.log(error)
  }
}

export function* fetchPrivacypolicySaga({payload}) {
  try {
    console.log("terms of use Sags")
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`static-pages/privacypolicy`,'get',null,headers)
    const {data} = res
    yield put(fetchprivacyPolicySuccess({data}))
    toaster('success',' Privacy Policy Fetched successufully')
  }
  catch (error) {
    toaster('error','Error Occured')
    console.log(error)
  }
}
