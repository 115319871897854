import React from "react";
import { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginInit} from "../../redux/actionCreators/authenticateActions";
import './login.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


const Login = () => {
  const [email, setEmail ] = useState("");
  const [password, setPass] = useState("");
  const loggedin = useSelector(state => state.adminState.loggedin)

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if(loggedin){
      history.push("/admin/home")
    }
  },[loggedin])
  
  const handleSubmit = (e) => {
    const data = {
      email: email,
      password: password
    }
    dispatch(loginInit(data))
  }
  const validation = (e) => {
    e.preventDefault()
    if(email !=='' && password !==''){
      handleSubmit()
      return
    }
    toast('Please fill the empty fields');
  }
    
  return(
    <div>
      <div className="main-div">
      <form onSubmit={validation} className="example_2">
        <h2 className="form_header">Admin Login</h2>
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            onChange={(event) => setPass(event.target.value)}
            value={password}
          />
        </div>
        <button
          type="submit"
          className="btn btn-dark btn-lg btn-block"
        >
          Sign in
        </button>
      </form>
      </div>
    </div>
  )
}

export default Login