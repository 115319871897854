const Policy = ({policy}) => {
  
  if(policy){
    console.log(policy[0].title)
    return(
      <div class="wrapper">
        <div className="content">
          <div className="container-fluid">  
            <h1>{policy[0].title}</h1>
          </div>
          <div className="container-fluid">  
            <div>
              {policy[0].description}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return(
    <div class="wrapper">
      <div className="content">
        <div className="container-fluid">  
          <h1>Privacy Policy</h1>
        </div>
        <div className="container-fluid">  
          <div>
            Nothing to show here
          </div>
        </div>
      </div>
    </div>
  )
}
export default Policy