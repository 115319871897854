import { useEffect, useState, React } from "react";
import { Link, useHistory } from "react-router-dom";
// import { useToasts } from 'react-toast-notifications';
// import { ToastContainer, toast } from 'react-toastify';

// imported component
import "./register.css";
import group from "../../Utils/login-left.png";
import { userRegister } from "../../services/api";


// imported icon
import { FiUser } from "react-icons/fi";
import { SiMaildotru } from "react-icons/si";
import { BsTelephone } from "react-icons/bs";
import { BiLockAlt, BiLockOpenAlt } from "react-icons/bi";

function Register() {
    const intialData = {
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        password: "",
        cpassword: "",
        userType: "user",
        loginType: "none",
        deviceType: "none",
        deviceToken: "n123123213asdas"
    };
    const history = useHistory();
    const [data, setData] = useState(intialData);
    const [message, setMessage] = useState(null);


    const { firstName, lastName, email, contactNo, password, cpassword } = data;


    const onValueChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        // console.log(data)
    };
    const toLoginPage = async (e) => {
        // await addContact(data); 
        e.preventDefault();
        // await toLogin(data)
        if (password !== cpassword) {
            setMessage("password does not match");
        } else {
            try {
                // dispatch({ type: USER_LOGIN_REQUEST });                
                // const response = await axios.post(`http://localhost:3000/api/auth/register`, data, config);
                const response = await userRegister(data);
                if (response.data) {
                    alert("user succesfully register");
                    // toast('Please fill the empty fields');
                    history.push("/signup");
                } else {
                    alert("fill registration data correctly");
                    // toast('Please fill the empty fields');
                    // addToast("content", {
                    //     appearance: 'error',
                    //     autoDismiss: true,
                    //   });
                }
                // localStorage.setItem("userInfo", JSON.stringify(response));    
                // console.log(response);
                // localStorage.setItem('document',JSON.stringify(this.state));
            } catch (error) {
                console.log("error in login", error)
            }
        }
    }
    const toLoginDetails = () => {
        history.push("/signup")
    }
    return (
        <>
            <section>
                <div className="row Registerstyle">
                    <div className="col-lg-6 col-xl-6 col-xxl-6 col-md-6 col-sm-12">
                        <img className="img-fluid Registerimgstyle" src={group} alt=".." />
                    </div>
                    <div className="col-lg-6 col-xl-6 col-xxl-6 col-md-6 col-sm-12 Registercontainer">
                        <div className="row">
                            <div className="col">
                                <Link className="nav-link nav-linka  Loginstylecss" to="/signup"><h1>Login</h1></Link>
                                {/* <div className="Loginline"></div> */}
                            </div>
                            <div className="col">
                                <Link className="nav-link nav-linka  Registerstylecss" to="/register" ><h1>Register</h1></Link>
                                <div className="Registerstyleline"></div>

                            </div>
                            {message && <h1>{message}</h1>}

                            {/* register form */}
                            <form>
                                <div className="registerformcontainer">
                                    <div className="last-input me-4 ">
                                        <i > <FiUser /> </i>

                                        <input type="text" name="firstName" value={firstName} placeholder="First Name" onChange={onValueChange} />
                                    </div>
                                    <div className="last-input me-4 ">  
                                        <i > <FiUser /> </i>

                                        <input type="text" name="lastName" value={lastName} placeholder="Last Name" onChange={onValueChange} />
                                    </div>
                                    <div className="last-input me-4 ">
                                        <i > <SiMaildotru /> </i>

                                        <input type="email" name="email" value={email} placeholder="Email" onChange={onValueChange} />
                                    </div>
                                    <div className="last-input me-4 ">
                                        <i > <BsTelephone /> </i>

                                        <input type="number" name="contactNo" value={contactNo} placeholder="contact" onChange={onValueChange} />
                                    </div>
                                    <div className="last-input me-4 ">
                                        <i > <BiLockOpenAlt /> </i>

                                        <input type="password" name="password" value={password} placeholder="Password" onChange={onValueChange} />
                                    </div>
                                    <div className="last-input me-4 ">
                                        <i > <BiLockAlt /> </i>

                                        <input type="password" name="cpassword" value={cpassword} placeholder="Confirm Password" onChange={onValueChange} />
                                    </div>
                                    <div className="lastsubmit-input ">
                                        <input type="submit" name="submit" value="Login" onClick={toLoginDetails} />
                                    </div>
                                    <div className="lastsubmit1-input ">
                                        <input type="submit" name="submit" value="Register" onClick={toLoginPage} />
                                    </div>
                                    {/* <div className="connectWith">
                                        <p>---- Or connect with ----</p>
                                    </div> */}

                                </div>

                            </form>

                            {/* <div className="symbolcont">
                                <div className="symbol">
                                    <img src={symbol1} alt="..." />
                                </div>
                                <div className="symbol">
                                    <img src={symbol2} alt="..." />
                                </div>
                                <div className="symbol">
                                    <img src={symbol3} alt="..." />
                                </div>
                                <div className="symbol">
                                    <img src={symbol4} alt="..." />
                                </div>
                            </div> */}

                        </div>

                    </div>


                </div>
            </section>

        </>
    );
}

export default Register;




