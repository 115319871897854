import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import _ from "lodash";
import './index.css'
import ReactPaginate from 'react-paginate'
import { deletestaticPageInitiate } from "../../redux/actionCreators/staticPageActions";

function StaticPageTable({tabledata,clic}) {
  console.log(tabledata,":tabledata")
  const [pageNumber,setPageNumber] = useState(0)
  const staticPageHeader = ["Title", "Description","Meta Tag","Meta Tag Desctiption"]
  const dispatch = useDispatch()
  const usersPerPage = 2
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata ? tabledata.slice(pageVisited, pageVisited+usersPerPage) : []
  const VisualRepresentation = () => {
    useEffect(() =>{
    },[])
    return(
      <Table responsive style={{letterSpacing: '0px', fontSize: 'large'}}>
      <thead>
        <tr>
          {staticPageHeader.map((item,key) => 
            <td key={key}>
              {item}
            </td>
          )}
        </tr>
      </thead>
      <tbody>
        {diplayusers.map((item,key) => (
          <tr key={key}>
            <td>
              {item.title}
            </td>
            <td>
              {item.description}
            </td>
            <td>
              {item.metaKeyword}
            </td>
            <td>
              {item.metaDescription}
            </td>
            <td>
              <Button color="primary" onClick={(event) => clickHandler(event,item._id,'vehicle')} style={{cursor: 'pointer'}}>Edit</Button>
            </td>
            <td>
              <Button color="danger" onClick={(event) => handleStaticPageDelete(event,item._id)}>Delete</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    )
  }

  const handleStaticPageDelete = (event,id) => {
    event.stopPropagation()
    dispatch(deletestaticPageInitiate(id))
  }

  const clickHandler = (event,id) => {
    event.stopPropagation();
    const formData = _.find(tabledata, (tabledata) => tabledata._id === id);
    clic(formData)
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default StaticPageTable;