

import { useHistory } 
from 'react-router';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import AriaModal from 'react-aria-modal'

import { useState } from 'react';
import ModalForm from "../Modal"

import {mediaurl} from '../../redux/sagas/utils'
import { PayPalButton } from "react-paypal-button-v2";
// import { toaster } from '../../../redux/sagas/utils';
import { deleteCourseInitiate } from '../../redux/actionCreators/courseActions';
// import OrderScreen from '../../orderscreen';

const CourseDetails = ({details}) => {
  const course = details
  const dispatch = useDispatch()
  const history = useHistory()
  const [modaldata, setmodaldata] = useState({})
  const [showmodal, setshowmodal] = useState(false)

  const [showCheckOut, setshowCheckOut] = useState(false)

  const deactivateModal = () => {
    setmodaldata({})
    setshowCheckOut(false)
    setshowmodal(false);
  };

  const arr = []
  const courseFiles = details ? details.courseFiles ? details.courseFiles : [] : []
  if(courseFiles){
    arr.push(courseFiles)
  }
  const handleEdit = () => {
    setmodaldata(details)
    setshowmodal(true)
  }

  const handleDelete = (id) => {
    dispatch(deleteCourseInitiate(id))
    history.push('/admin/courses')
  }
  // const userType = JSON.parse(localStorage.getItem("userData")).userType
  const videoLink = `${mediaurl}${details.courseFiles}`
  return(
    <div style={{width: '100%'}}>
      <div style={{width: '100%'}}>
      {showmodal && <ModalForm show={"course"} deactivate={deactivateModal} item={modaldata}/>}
          <h1 style={{textAlign: 'center'}}>{details.course_title}</h1>
          <div style={{width: '80%', height: '400px'}}>
            <img style={{width: '100%',height: '100%' }} src={`${mediaurl}${details.courseImage}`} alt="courseImage" />
          </div>
          <h1> Price :   ${details.course_price}</h1>
          <h1> Description : {details.course_description}</h1>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button
            color="primary"
            onClick={() => handleEdit()}
            style={{float: "left", marginRight:"10px", marginTop:"10px"}}
          > EDIT </Button>
          <Button color="danger" onClick={() => handleDelete(details._id)}>DELETE</Button>
        </div>
      </div>
      
      <video width='320' height= '240'  controls >
        <source src={videoLink} />
        {/* Your browser does not support the video tag. */}
      </video>
     {
      //  userType === 'user'
      // ? 
      // {showmodal && <ModalForm show={show} deactivate={deactivateModal} item={modaldata}/>}
      showCheckOut ?
          <div>
          <AriaModal
            titleText="demo one"
            focusDialog={false}
            focusTrapPaused={false}
            onExit={deactivateModal}
            underlayStyle={{ paddingTop: "2em" }}
          // underlayStyle={{ paddingTop: "2em" }}
        >
          {/* <OrderScreen course={details} /> */}
          
          </AriaModal>
          
        </div>
      //   : 
      //   <Button onClick={() =>setshowCheckOut(!showCheckOut)}>Checkout
      //       {/* <Butn amount={details.course_price} purchased={details} /> */}
      //     </Button>
      :
        ''
      }
   
    </div>
  )
}
export default CourseDetails