import { settingsActions } from "../actionTypes";

export const fetchSettingsInitiate = (data) => {
  return {
    type: settingsActions.FETCH_SETTINGS_INITIATE,
    payload: data,
  };
};

export const fetchSettingsSuccess = (data) => {
  return {
    type: settingsActions.FETCH_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const editSettingsInitiate = (data) => {
  return {
    type: settingsActions.EDIT_SETTINGS_INITIATE,
    payload: data,
  };
};

export const editSettingsSuccess = (data) => {
  return {
    type: settingsActions.EDIT_SETTINGS_SUCCESS,
    payload: data,
  };
};