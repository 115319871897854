import React from "react";
import AriaModal from 'react-aria-modal'
import { Button, Label } from "reactstrap";
import AddEditForm from '../Forms/FormAddEdit'
import './index.css'

const Modal = ({
  deactivate,
  show,
  type,
  item,
  search,
  searchEdit,
  addInSearch,
}) => {
  return (
    <AriaModal
      titleText="demo one"
      onExit={deactivate}
      underlayStyle={{ paddingTop: "2em" }}
    >
      <div className="modal-container">
      <AddEditForm
        search={search}
        addInSearch={addInSearch}
        searchEdit={searchEdit}
        submitType={type}
        formtype = {show}
        toggle={deactivate}
        item={item}
      />
      </div>
    </AriaModal>
  );
};

export default Modal