import './index.css'
import Navbar from "../navbar";
import CustomSidebar from "../sidebar";
import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import Policy from './policy';
import { fetchprivacyPolicyInitiate, fetchtermsOfuseInitiate } from '../../redux/actionCreators/staticPageActions';

const PrivacyPolicy = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('transaction history')  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }

  // const terms = useSelector(state => state.vehicleState.termsOfUse)
  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchprivacyPolicyInitiate());
    })
  },[])
  
  const width = siddebar ? '350px' : '100px'
  
  console.log(useSelector(state => state.staticpageState.privacyPolicy))
  const policy = (useSelector(state => state.staticpageState.privacyPolicy))
  
  return (
    <div>
      <Navbar changeSidebar={changeSelected} />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
        <div style={{marginLeft: width , marginTop: '96px', width: '100%'}}>
          <Policy show={siddebar} policy = {policy}  />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;