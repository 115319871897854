import Navbar from "../navbar";
import StaticPages from './staticpage'
import CustomSidebar from "../sidebar";
import React, { useState } from "react";
import AddStaticPage from "./addStaticPage";
import './index.css'

const AddStaticPageDisplay = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('dashboard')
  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }

  return (
    <div>
      <Navbar changeSidebar={changeSelected} />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
      <AddStaticPage show={siddebar} />
      </div>
    </div>
  );
}

export default AddStaticPageDisplay;