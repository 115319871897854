import axios from 'axios'
import { toast } from 'react-toastify';
import { set, has, isEmpty } from 'lodash';
import produce from 'immer'

export const mediaurl = 'http://localhost:3000/';
const baserurl = 'http://localhost:3000/api/';

export function makereq(url,method,data,headers){
  
  var config = {
    method: method,
    url: baserurl+url,
    headers: headers,
    data : data,
  };
  return new Promise((resolve,reject) =>{
    axios(config)
    .then((res) =>resolve(res.data))
    .catch(function (error) {
      console.log(error)
    if (error.response) {
      if(error.response.data.message){
        if(error.response.data.message === 'invalid token...'){
          toaster("error","Session Expired please logout and login again")
          return
        }
      }
      reject(error.response.data);
    } else if (error.request) {
      reject(error.request);
    }
  });
  })
}

export function stringvalidator(name){
  if(!name){
    return false
  }
  if(name.trim().length === 0){
    return false
  }
  return true
}

export function toaster(type,msg){
  if(type=== 'error'){
    toast.error(msg,{
      position: "top-right",
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false
    })
    return
  }
  if(type === 'success'){
    toast.success(msg,{
      position: "top-right",
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false
    })
    return
  }
  if(type === 'warning'){
    toast.warning(msg,{
      position: "top-right",
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false
    })
    return
  }
  toast.info(msg,{
    position: "top-right",
    autoClose: 2000,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false
  })
  return
}

export const enhancedReducer = (state, updateArg) => {
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) }
  }

  if (updateArg.constructor === Object) {
    if (has(updateArg, 'path') && has(updateArg, 'value')) {
      const { path, value } = updateArg

      return produce(state, (draft) => {
        set(draft, path, value)
      })
    } else if (!isEmpty(updateArg)) {
      return { ...state, ...updateArg }
    }
  }

  return state
}

export const isObjEmpty = (obj1) => {
  let flag = true
  const traverseNode = (arr, id, n, obj) => {
    if (id >= n) return

    if (obj[arr[id]] instanceof Object) {
      traverseNode(
        Object.keys(obj[arr[id]]),
        0,
        Object.keys(obj[arr[id]]).length,
        obj[arr[id]]
      )
    } else if (obj[arr[id]] !== '') {
      flag = false

      return
    } else {
      traverseNode(arr, id + 1, n, obj)
    }
  }
  traverseNode(Object.keys(obj1), 0, Object.keys(obj1).length, obj1)

  return flag
}