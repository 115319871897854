import { watcher } from "./watcher"
import {createStore, applyMiddleware, combineReducers} from "redux"
import createSagaMiddleware from "redux-saga"
import { userReducer } from "./reducer/userReducer"
import { staticpageReducer } from "./reducer/staticpagereducer"
import { transactionReducer } from "./reducer/transactionreducer"
import { authReducer } from "./reducer/authreducer"
// import { vehicleReducer } from "./reducer/vehiclereducer"
// import { shopcategoryReducer } from "./reducer/shopcategory"
// import { settingsReducers } from "./reducer/settingsreducers"
// import { vehicleCategoryReducer } from "./reducer/vehiclecategoryreducer"
// import { productCategoryReducer } from "./reducer/productcategortreducer"
// import { productSubCategoryReducer } from "./reducer/productSubcatreducer"
// import { orderReducer } from "./reducer/orderReducer"
import { contactUsReducer } from "./reducer/contactusReducer"
import { courseReducer } from "./reducer/courseReducer"
const sagaMiddleWare = createSagaMiddleware()

const rootReducer = combineReducers({
  courseList: courseReducer,
  staticpageState: staticpageReducer,
  transactionState: transactionReducer,
  userList: userReducer,
  adminState: authReducer,
  // orderState: orderReducer,
  // settingsState: settingsReducers,
  contactUSData: contactUsReducer,
})

const store =  createStore(rootReducer, applyMiddleware(sagaMiddleWare))
sagaMiddleWare.run(watcher)

export default store