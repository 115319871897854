import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";


// imported component
import "./Checkout.css";
import { getPostById, addPurchase_course, paypalInte } from "../../services/api";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/footer";
import paypal from "../../Utils/paypal-ar21.svg";
import stripe from "../../Utils/stripe-ar21.svg";
import PayPal from "../payment/Paypal";
// import Congrate from "../congratulationpage/congrats";



function Checkout() {
    const { id } = useParams();
    const course_id = id;
    const history = useHistory();
    const [order, setOrder] = useState({});
    const [post, setPost] = useState({})
    // const [paydata, setPaydata] = useState({})
    const [token, setToken] = useState("");
    const [checkout, setCheckOut] = useState("");





    const handleOnSubmit = () => {
        history.push("/signup")
    }


    useEffect(() => {

        const hello = async () => {

            // for getting data of course by id

            let response = await getPostById(course_id);
            if (response) {
                var course = (response.data.data);
                setPost(response.data.data);
            }

            // for user id purchased course

            var data = JSON.parse(localStorage.getItem('userInfo'));
            if (data) {

                var userdata = data.data.data;
                // console.log(userdata._id);

                setToken(userdata.token);
                // console.log(userdata.token);
                // set data in order

                setOrder({
                    id: userdata._id,
                    user: userdata.userName,
                    amount: course.course_price,
                    courseId: course._id,
                    paymentStatus: "pending",
                    status: "for payment",
                    method: checkout
                });
            }

        }
        hello();

    }, []);
    // console.log(checkout);
    // console.log(order.amount);
    let price = order.amount;


    const handleToPurchase = async () => {

        // await addPurchase_course(order, token);
        // await paypalInte(paydata, token);
        // setCheckOut(true);


    }
    const handleChange = (e) => {
        setCheckOut(e.target.value);
    }
    const [drive, setDrive] = useState(null);
    const sendDataToParent = (index) => {
        console.log(index);
        setDrive(index);
    };
    console.log(drive)




    return (

        <>
            <Navbar />

            <section>
                <div className="banner123">
                    <div className="bannerimgs123">
                        <h1 className="head">WEB DEVELOPMENT BOOTCAMP</h1>
                        <p className="para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
                <div className="row containercart">
                    <div className="col-lg-8 col-md-12 ">
                        <div>
                            <div>
                                <p className="clicklogin">
                                    Already login ?
                                    <span className="text-warning" style={{ "fontSize": "20px", marginLeft: "1%" }} onClick={handleOnSubmit}>Click here to login</span>
                                </p>
                                <p className="clicklogin">
                                    Have a coupon ?
                                    <span className="text-warning" style={{ "fontSize": "20px", marginLeft: "1%" }} onClick={handleOnSubmit}>Click here to enter your code</span>
                                </p>
                                <div className="row billingform">
                                    <div className="col-lg-6 col-md-12">
                                        {/* <h1 className="bilingdetails">Billing details</h1> */}
                                        <form>
                                            {/* <div className="row">
                                                <div className="mb-4 text-start col-6">
                                                    <label className="form-label text-start">First name*</label>
                                                    <input type="email" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter First name" />
                                                </div>
                                                <div className="mb-4 text-start col-6">
                                                    <label for="exampleFormControlInput1" className="form-label">Last name*</label>
                                                    <input type="text" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter Last name" />
                                                </div>
                                            </div>
                                            <div className="mb-4 text-start">
                                                <label for="exampleFormControlInput1" className="form-label">Last name*</label>
                                                <input type="text" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter Last name" />
                                            </div> */}
                                            {/* <div className="mb-4 text-start">
                                                <p>Country / Region *</p>
                                                <select className="form-select formfield" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div> */}
                                            {/* <div className="mb-4 text-start">
                                                <label for="exampleFormControlInput1" className="form-label">Street address *</label>
                                                <input type="text" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter Street address *" />
                                            </div>
                                            <div className="mb-4 text-start">
                                                <label for="exampleFormControlInput1" className="form-label">Town / City *</label>
                                                <input type="text" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter Town / City *" />
                                            </div> */}
                                            {/* <div className="mb-4 text-start">
                                                <p>State / County *</p>
                                                <select className="form-select formfield" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div> */}
                                            {/* <div className="mb-4 text-start">
                                                <label for="exampleFormControlInput1" className="form-label">Postcode / ZIP *</label>
                                                <input type="text" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter Postcode / ZIP *" />
                                            </div>
                                            <div className="mb-4 text-start">
                                                <label for="exampleFormControlInput1" className="form-label">Phone *</label>
                                                <input type="text" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter Postcode / ZIP *" />
                                            </div>
                                            <div className="mb-4 text-start">
                                                <label for="exampleFormControlInput1" className="form-label">Email address *</label>
                                                <input type="text" className="form-control formfield" id="exampleFormControlInput1" placeholder="Enter Email address *" />
                                            </div> */}


                                        </form>

                                    </div>
                                    {/* <div className='col-lg-6 col-md-0'>
                                        <h1 className="additionalinfo ">Additional information</h1>
                                        <div className="text-start">
                                            <label for="exampleFormControlInput1" className="form-label ms-4 ">Order notes (optional)</label>
                                            <input type="text" className="form-control formoptionalfield" id="exampleFormControlInput1" placeholder="Lorem Ipsum is simply dummy text of the" />
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                        <div className="">
                            <h1 className="tablestyle123">
                                Cart totals
                            </h1>
                            <div className=" table-responsive">
                                <table className="table table-border tablestyle23">

                                    <tbody>

                                        <tr>
                                            <th scope="row"><p className="ms-4 ">Product</p></th>
                                            <th className="text-end" colspan="2"><p>Sub total</p></th>
                                        </tr>

                                        <tr>
                                            <td scope="row">
                                                <p className="ms-4">{post.course_title}</p>
                                                {/* <p className="ms-4">JavaScript & Node.js</p>
                                                <p className="ms-4">JavaScript & Node.js</p> */}
                                            </td>
                                            <td className="text-end" colspan="2">
                                                <p className="me-4">${post.course_price}</p>
                                                {/* <p className="me-4">$1300</p>
                                                <p className="me-4">$1300</p> */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><p className="ms-4" >Total</p></th>
                                            <th className="text-end" colspan="2"><p className="me-4">${post.course_price}</p></th>
                                        </tr>
                                    </tbody>
                                </table>

                                <div >
                                    <h1 className="selectPayH" >Select Payment Method</h1>
                                    <div className='payment'>
                                        <form>
                                            <div class="form-check-inline warning text-start " >
                                                <input class="form-check-input" type="radio" name="payment" value="paypal" id="apple" onClick={handleChange} />
                                                <label class="form-check-label" for="paypal">
                                                    <img className="ms-4 imgpayment" src={paypal} alt="..." />
                                                </label>
                                            </div>
                                            <div class="form-check-inline text-start " >
                                                <input class="form-check-input" type="radio" name="payment" value="stripe" id="orange" onClick={handleChange} />
                                                <label class="form-check-label" for="stripe">
                                                    <img className="ms-4 imgpayment" src={stripe} alt="..." />
                                                </label>
                                            </div>

                                        </form>

                                    </div>


                                    {/* <label>Gender</label>
    <Form.Radio label="Male" checked={gender === 'Male'} value="Male" onClick={() => setGender('Male')} />
    <Form.Radio label="Female" checked={gender === 'Female'} value="Female" onClick={() => setGender('Female')} /> */}
                                    <div className="parapaymentdiv">
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the indu stry's standard dummy text ever since the 1500s,
                                        </p>
                                        {/* <button type="button " className="btn btn-dark tablestyle23but"
                                            onClick={handleToPurchase}>Place Order</button> */}
                                        {checkout === "paypal" ? (<>
                                            <PayPal par={order} token={token}
                                                sendDataToParent={sendDataToParent} />
                                            {/* <Congrate></Congrate> */}
                                        </>
                                        ) : (
                                            <button className="btn btn-dark tablestyle23but"
                                                onClick={handleToPurchase}
                                            >
                                                Checkout
                                            </button>
                                        )}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    {/* left part */}
                    <div className="col-lg-4 col-md-0  ">
                        {/* <div></div> */}
                        <div className="tableweekend">

                            <h1 className="text-start weekendHeading
                        ">Working Hours</h1>

                            <div className=" table-responsive table-border ">
                                <table className="table">

                                    <tbody>
                                        <tr>
                                            <td className="text-start tablecontent" scope="row">Monday</td>
                                            <td className="text-end tablecontent">9am - 6pm</td>

                                        </tr>
                                        <tr>
                                            <td className="text-start tablecontent" scope="row">tuesday</td>
                                            <td className="text-end tablecontent">9am - 6pm</td>

                                        </tr>
                                        <tr>
                                            <td className="text-start tablecontent" scope="row">Wednesday</td>
                                            <td className="text-end tablecontent" colspan="2">9am - 6pm</td>

                                        </tr>
                                        <tr>
                                            <td className="text-start tablecontent" scope="row">Thursday</td>
                                            <td className="text-end tablecontent" colspan="2">9am - 6pm</td>

                                        </tr>
                                        <tr>
                                            <td className="text-start tablecontent" scope="row">Friday</td>
                                            <td className="text-end tablecontent" colspan="2">9am - 6pm</td>

                                        </tr>
                                        <tr>
                                            <td className="text-start tablecontent" scope="row">Saturday</td>
                                            <td className="text-end " colspan="2">
                                                <p class="btn btn-dark tablecontentbut">Closed</p>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="text-start tablecontent" scope="row">Sunday</td>
                                            <td className="text-end" colspan="2">
                                                <p class="btn btn-dark tablecontentbut">Closed</p>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                                {/* <div>
                                    <h1 className="helloteachers">Teachers</h1>
                                    <div className="team123 my-4">

                                        <h1 className=" team123h" >Johnson (CEO)</h1>
                                        <p className=" team123p">Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has.</p>
                                        <div className='linelogos123'>
                                            <FaFacebook className="facebook" />
                                            <FaTwitter className="twitter" />
                                            <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                                        </div>
                                    </div>
                                    <div className="team123 my-4">

                                        <h1 className=" team123h" >Johnson (CEO)</h1>
                                        <p className=" team123p">Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has.</p>
                                        <div className='linelogos123'>
                                            <FaFacebook className="facebook" />
                                            <FaTwitter className="twitter" />
                                            <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                                        </div>
                                    </div>

                                </div> */}
                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <Footer />
        </>
    );
}

export default Checkout;
