import { useEffect, useState } from "react";
import { useHistory } from "react-router"
import CustomSidebar from "../sidebar";
import Navbar from "../navbar";
import { useDispatch, useSelector } from "react-redux";
import { getcoursesbyuserInitiate } from "../../redux/actionCreators/userActions";
import { mediaurl, toaster } from "../../redux/sagas/utils";
import UserCCourses from "./useCourseCard";
import { Button } from "reactstrap";
const UserCourses = () => {
  const history = useHistory();
  const id = history.location.pathname.split('/')
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('user list')
  const [search,setSearch] = useState("")
  const [searchres,setSearchRes] = useState("")
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }
  const dispatch = useDispatch()
  const splittedid = id[id.length-1]
  console.log(splittedid)
  useEffect(() => {
    dispatch(getcoursesbyuserInitiate(splittedid))
  },[])

  
  const handleCancel = () => {
    setSearch('')
    setSearchRes('')
  }

  const mapper = useSelector(state=>state.userList.courseofUsers)
  console.log(mapper)
  const handleSubmit = () => {
    console.log("searchSi=ubmit")
    if(search.trim() && mapper && mapper.length > 0){
        console.log(mapper)
        const newArr = mapper.filter((itm)=> itm.course_title === search)
        console.log(newArr)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
        toaster("success","could not find anything for your search")
        return
      }
  }

  const width = siddebar ? '350px' : '100px'

  return(
    <div>
      <Navbar
        inputstate={search}
        inputchange={(value) => setSearch(value)}
        handleSubmit={handleSubmit}
        changeSidebar={changeSelected}
      />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
      </div>
      <div style={{marginLeft: width,marginTop: '96px',width: '100%'}}>
        <h1>USER COURSES</h1>
        {searchres && 
        <div style={{display: 'flex', justifyContent: 'flex-start', lineHeight: '15px', marginRight: '3%'}}>
        <span>Search Results</span>  <Button style={{margin: '0px 100px 10px 100px'}} color="primary" onClick={handleCancel}>Go Back</Button>
        </div>      
      }
        {mapper.map((itm) => {
          return(
            <div onClick={() => history.push(`/admin/courseDetails/${itm._id}`)}>
              <UserCCourses data={itm}  show={siddebar}/>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default UserCourses