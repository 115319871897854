import { useEffect, useState, React } from "react";
import Navbar from '../Navbar/Navbar';
import { FaGooglePlusG, FaFacebookF, FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

// imported component
import Banner from "../../Utils/Banner@2x.png";
import "./About.css";
import { FaChevronRight } from "react-icons/fa";
import group from "../../Utils/Group 11026.png";
import Card from '../home/Course_card';
import { getAllposts ,getStaticContent} from "../../services/api";
import Footer from "../footer/footer";
import map from "../../Utils/Mask Group 52@2x.png";
import contactimg from "../../Utils/contact-img.png";


function AboutUs() {
  const [posts, setPosts] = useState([]);
  const [about_poster_1,setAbout_poster_1] = useState([]);
  const [about_head_text,setAbout_head_text] = useState([]);
  const [about_head_2_text,setAbout_head_2_text] = useState([]);
  const [about_line_text_1,setAbout_line_text_1] = useState([]);
  const [about_line_text_2,setAbout_line_text_2] = useState([]);
  const [about_line_text_3,setAbout_line_text_3] = useState([]);
  const [about_popular_text,setAbout_popular_text] = useState([]);
  const [about_teachers_head,setAbout_teachers_head] = useState([]);
  
  const [about_card_text,setAbout_card_text] = useState();
  const [about_card_head,setAbout_card_head] = useState();

  const [about_teachers_card_1,setAbout_teachers_card_1] = useState();
  const [about_teachers_card_2,setAbout_teachers_card_2] = useState();
  const [about_teachers_card_3,setAbout_teachers_card_3] = useState();
  const [about_teachers_card_4,setAbout_teachers_card_4] = useState();

  // const { search } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAllposts();
      const response_about_poster_1 = await getStaticContent("about_poster_1");
      const response_about_head_text = await getStaticContent("about_head_text");
      const response_about_head_2_text = await getStaticContent("about_Head_2_text");
      const response_about_line_text = await getStaticContent("about_line_text");
      const response_about_popular_text = await getStaticContent("about_popular_text");
      const response_about_teachers_head = await getStaticContent("about_teachers_head");
      const response_about_teachers_card = await getStaticContent("about_teachers_card");
      const response_about_card_text = await getStaticContent("about_card_text");
      const response_about_card_head = await getStaticContent("about_card_head");


     
      
      if (response) {
        // console.log(response.data);
        setPosts(response.data.data);
        // console.log(response.data.data);     
      }
      if (response_about_poster_1) {
        setAbout_poster_1(response_about_poster_1.data.message[0]);
      }
      if (response_about_head_text) {
        setAbout_head_text(response_about_head_text.data.message[0]);
      }
      if (response_about_head_2_text) {
        setAbout_head_2_text(response_about_head_2_text.data.message[0]);
      }
      if (response_about_line_text) {
        setAbout_line_text_1(response_about_line_text.data.message[0]);
        setAbout_line_text_2(response_about_line_text.data.message[1]);
        setAbout_line_text_3(response_about_line_text.data.message[2]);
      }
      if (response_about_popular_text) {
        setAbout_popular_text(response_about_popular_text.data.message[0]);
      }
      if (response_about_teachers_head) {
        setAbout_teachers_head(response_about_teachers_head.data.message[0]);
      }
      if (response_about_card_text) {
        setAbout_card_text(response_about_card_text.data.message[0]);
      }
      if (response_about_card_head) {
        setAbout_card_head(response_about_card_head.data.message[0]);
      }
      if (response_about_teachers_card) {
        setAbout_teachers_card_1(response_about_teachers_card.data.message[0]);
        setAbout_teachers_card_2(response_about_teachers_card.data.message[1]);
        setAbout_teachers_card_3(response_about_teachers_card.data.message[2]);
        setAbout_teachers_card_4(response_about_teachers_card.data.message[3]);

      }
    }
    fetchData();
  }, []);

  // console.log(about_card_head);


  return (
    <>

      <Navbar />
      <section className="container-fluid">
        <div className="row">
          <div className="col-sm-0 col-md-4 col-lg-4 p-0">
            <div className="testtest-newnew">
              <img src={Banner} className="img-fluid" alt="..."></img>
            </div>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 p-0 cardb">

            {/* carousel */}
            <div className="cardaa">
              <h1 className="cardhh">{ about_poster_1 && about_poster_1.title || "About Us" }</h1>
              <p className="cardpp">{about_poster_1&&about_poster_1.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever."}</p>
              <div className="last12">
                <FaFacebookF className="icon123" />
                <FaTwitter className="icon123" />
                <FaGooglePlusG className="icon123" />
                <FaLinkedin className="icon123" />
                <FaInstagram className="icon123" />
              </div>
            </div>

          </div>
        </div>
      </section>
      <section>
        <div className="lorem">
          <h1  >{about_head_text&&about_head_text.title||"Lorem Ipsum Is Simply"}</h1>

          <p>{about_head_text&&about_head_text.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br />Lorem Ipsum has been the industry's standard dummy."}</p>
        </div>
      </section>
      <section className="container-fluid">
        <div className="row series">
          <div className="col-md-4 col-lg-4 top-0 start-0 ">
            <div className="display">
              <div>
                <p>01</p>

                <h5 className="mb-4 fw-bold">{about_line_text_1&&about_line_text_1.title||"Studies"}</h5>
                <p>{about_line_text_1&&about_line_text_1.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been "}</p>
                {/* <button type="button" class="btn btn-dark mt-2 "> lorem ipsum <FaChevronRight /></button> */}
              </div>
              <div className="liness"></div>
            </div>

          </div>
          <div className="col-md-4 col-lg-4 top-0 start-0  ">
            <div className="display hellos">
              <div>
                <p>02</p>

                <h5 className="mb-4 fw-bold">{about_line_text_2&&about_line_text_2.title||"Courses"}</h5>
                <p>{about_line_text_2&&about_line_text_2.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been "}</p>
                {/* <button type="button" class="btn btn-dark mt-2 "> lorem ipsum <FaChevronRight /></button> */}
              </div>
              <div className="liness"></div>
            </div>

          </div>

          <div className="col-md-4 col-lg-4 p-0 " >
            <div className="helloo">
              <p>03</p>

              <h5 className="mb-4 fw-bold" >{about_line_text_3&&about_line_text_3.title||"trainingss"}</h5>
              <p>{about_line_text_3&&about_line_text_3.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"} </p>
              {/* <button type="button" class="btn btn-dark mt-2 "> lorem ipsum <FaChevronRight /></button> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="aboutus">
          <h1>{about_head_2_text&&about_head_2_text.title||"About Us"}</h1>
          <p>{about_head_2_text&&about_head_2_text.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been "}</p>

        </div>
        <div className="imgaboutus">
          <img className="img-fluid" src={group}></img>
        </div>
      </section>
      {/* popular course */}
      <section className="seccourses">
        <div className="text-center textcourse ">
          <h1 >
            {about_popular_text&& about_popular_text.title||"Popular courses"}
          </h1>
          <p >
            {about_popular_text&&about_popular_text.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />Ipsum has been the industry's standard dummy."}
          </p>
        </div>
        <div className="apptistcontainer">
          <p className='text-end  viewtext'>ViewAll</p>
          <div>
            <div className="row">
              {posts.map(post => (
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <Card post={post} />
                </div>


              ))
              }



            </div>
          </div>

        </div>
      </section>
      {/* teachers */}
      <section>
        <div className="text-center p-4 m-4">
          <h1 className="fw-bold">
            {about_teachers_head&&about_teachers_head.title||"Teachers"}
          </h1>
          <p >
            {about_teachers_head&&about_teachers_head.description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."}
          </p>
        </div>
        <div className="apptistcontainer container-fluid">
          <div className='row'>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 ">
              <div className="team">

                <h1 className=" teamh" >{about_teachers_card_1&&about_teachers_card_1.title||"Johnson (CEO)"}</h1>
                <p className=" teamp">{about_teachers_card_1&&about_teachers_card_1.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                <div className='linelogos'>
                  <FaFacebook className="facebook" />
                  <FaTwitter className="twitter" />
                  <button type="button" class="btn btn-warning bts"><FaChevronRight/></button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 ">
              <div className="team">

                <h1 className=" teamh" >{about_teachers_card_2&&about_teachers_card_2.title||"Johnson (CEO)"}</h1>
                <p className=" teamp">{about_teachers_card_2&&about_teachers_card_2.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                <div className='linelogos'>
                  <FaFacebook className="facebook" />
                  <FaTwitter className="twitter" />
                  <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3">
              <div className="team">

                <h1 className=" teamh" >{about_teachers_card_3&&about_teachers_card_3.title||"Johnson (CEO)"}</h1>
                <p className=" teamp">{about_teachers_card_3&&about_teachers_card_3.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                <div className='linelogos'>
                  <FaFacebook className="facebook" />
                  <FaTwitter className="twitter" />
                  <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 ">
              <div className="team">

                <h1 className=" teamh" >{about_teachers_card_4&&about_teachers_card_4.title||"Johnson (CEO)"}</h1>
                <p className=" teamp">{about_teachers_card_4&&about_teachers_card_4.description||"Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has."}</p>
                <div className='linelogos'>
                  <FaFacebook className="facebook" />
                  <FaTwitter className="twitter" />
                  <button type="button" class="btn btn-warning bts"><FaChevronRight /></button>

                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* cards & form */}
      <section>
        <div className='container-fluid' >
          <div className="row imgcontainer">

            <div className=" col-md-4 col-lg-4 p-0 position-relative " >
              {/* <img src={Mask} className="img-fluid" alt="..."></img> */}
              <img src={map} className="img-fluid" alt="..."></img>


            </div>
            <div className="col-md-4 col-lg-4  p-0 position-relative " >
              {/* <img src={Mask} className="img-fluid" alt="..."></img> */}
              <img src={contactimg} className="img-fluid" alt="..."></img>

            </div>
            <div className="col-md-4 col-lg-4 p-0 position-relative backg" >
              <div className=" top-0 start-0 cardtext">
                <h5>{about_card_head&&about_card_head.description||"Lorem ipsum is simply"}</h5>
                {/* <div className="single-input">
                  <input type="text" name="name" placeholder="NAME" />
                </div>
                <div className="single-input">
                  <input type="email" name="email" placeholder="Email" />
                </div> */}
                <p className="companyname">{about_card_text&&about_card_text.title||"Apptist"} </p>
                <p className="companymail">{about_card_text&&about_card_text.description||"apptist@mail.com"}</p>
                {/* <button type="button" class="btn btn-dark mt-4 "> lorem ipsum </button> */}
              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutUs;




