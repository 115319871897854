import { coursesActions } from "../actionTypes";

export const fetchCourseInitiate = (data) => {
  return {
    type: coursesActions.GET_COURSE_INITIATE,
    payload: data,
  };
};

export const fetchCourseSuccess = (data) => {
  
  console.log(data)
  return {
    type: coursesActions.GET_COURSE_SUCCESS,
    payload: data,
  };
};

export const addcourseInitiate = (data) => {
  return {
    type: coursesActions.ADD_COURSE_INITIATE,
    payload: data,
  };
};

export const addcourseSuccess = (data) => {
  console.log(data)
  return {
    type: coursesActions.ADD_COURSE_SUCCESS,
    payload: data,
  };
};

export const getCourseDetailsInitiate = (data) => {
  return{
    type: coursesActions.GET_COURSE_DETAILS_INITIATE,
    payload: data,
  }
}

export const getCourseDetailsSuccess = (data) => {
  return{
    type: coursesActions.GET_COURSE_DETAILS_SUCCESS,
    payload: data,
  }
}

export const deleteCourseInitiate = (data) => {
  return{
    type: coursesActions.DELETE_COURSE_INITIATE,
    payload: data
  }
}

export const deleteCourseSuccess = (data) => {
  return{
    type: coursesActions.DELETE_COURSE_SUCCESS,
    payload: data
  }
}


export const editCourseInitiate = (data) => {
  console.log(data,"edit course saga")
  return{
    type: coursesActions.EDIT_COURSE_INITIATE,
    payload: data
  }
}

export const editCourseSuccess = (data) => {
  return{
    type: coursesActions.EDIT_COURSE_SUCCESS,
    payload: data
  }
}

export const addtransactinoInitiate = (data) => {
  return{
    type: coursesActions.ADD_TRANSACTION_INITIATE,
    payload: data
  }
}

export const addTransactionSuccess = (data) => {
  return{
    type: coursesActions.ADD_TRANSACTION_SUCCESS,
    payload: data
  }
}

export const generateOrderInitiate = (data) => {
  return{
    type: coursesActions.GENERATE_ORDER_INITIATE,
    payload: data
  }
}

export const generateOrderSuccess = (data) => {
  return{
    type: coursesActions.GENERATE_ORDER_SUCCESS,
    payload: data
  }
}