import './index.css';
import logo from '../../assets/Group_11019.png'
import SocialMediaIcons from 'react-social-media-icons';
import { Link } from 'react-router-dom';

const Footer = () =>{
  const socialMediaIcons = [
    {
      // url: 'https://github.com/andrewgbliss',
      className: 'fa-facebook',
    },
    {
      // url: 'https://gitlab.com/andrewgbliss',
      className: 'fa-google',
    },
    {
      // url: 'https://www.npmjs.com/~andrewgbliss',
      className: 'fa-twitter',
    },
    {
      // url: 'https://www.npmjs.com/~andrewgbliss',
      className: 'fa-instagram',
    },
  ];

  return(
    <div style={{height: '150px', width:'100%', backgroundColor: '#5C5858', color: 'white', fontSize: '15px' }}>
      <div>
        <span className="span123!"><Link to="/termsofuse" style={{color: 'white'}}>Terms</Link></span>        <span><Link to="/privacyPolicy" style={{color: 'white'}}>Privacy Policy</Link></span>
        <hr />
      </div>
      <div className="bottom">
        <div>
        <span className="span123!"><img src={logo} alt="LOGO"/></span> Copyright &copy; Apptist Academy all right reserved<span>Privacy Policy</span>
        </div>
        <div>
        <SocialMediaIcons
          icons={socialMediaIcons}
          iconSize='1px'
          iconColor={'white'}
        />
        </div>
      </div>
    </div>
  )
}
export default Footer