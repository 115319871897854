import Navbar from "../navbar";
import StaticPages from './staticpage'
import CustomSidebar from "../sidebar";
import React, { useEffect, useState } from "react";
import { toaster } from "../../redux/sagas/utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchstaticPageInitiate } from "../../redux/actionCreators/staticPageActions";

const StaticPageDisplay = () => {
  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('pages')

  const [search,setSearch] = useState("")
  const [searchres,setSearchRes] = useState("")
  const dispatch = useDispatch()

  useEffect (() => {
    dispatch(fetchstaticPageInitiate())
  }, [])
  const {staticpagelist} = useSelector(state => state.staticpageState)
  
  
  const handleSubmit = () => {
    console.log(search)
    if(search.trim()){
      const newArr = staticpagelist.filter((itm)=> itm.title === search)
      console.log(newArr)
      if(newArr.length){
        setSearchRes(newArr)
        return
      }
      toaster("success","could not find anything for your search")
      return
    }
  }

  const handleCancel = () => {
    setSearchRes('')
    setSearch('')
  }

  const data = searchres ? searchres : staticpagelist
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }

  return (
    <div>
      <Navbar
        inputstate={search}
        inputchange={(value) => setSearch(value)}
        handleSubmit={handleSubmit}
        changeSidebar={changeSelected}
      />
      <div style={{display: "flex"}}>
        <CustomSidebar
          show={siddebar}
          selected={selected}
          changeSelected={(el) => setSelected(el)}
        />
      <StaticPages
        back={handleCancel}
        searched={searchres ? true : false}
        pages = {data}
        show={siddebar}
      />
      </div>
    </div>
  );
}
export default StaticPageDisplay;