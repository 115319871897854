import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PurchasedCourse } from '../../assets/PurchasedCourse.svg';
import { ReactComponent as Skilllevel } from '../../assets/Skilllevel.svg';
import { ReactComponent as TotalDuration } from '../../assets/TotalDuration.svg';
import { ReactComponent as TotalStudents } from '../../assets/TotalStudents.svg';
import { fetchCourseInitiate } from '../../redux/actionCreators/courseActions';
import { getAllUsersInitiate } from '../../redux/actionCreators/userActions';


const Cards = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    batch(()=>{
      dispatch(getAllUsersInitiate())
      dispatch(fetchCourseInitiate())
    })
  },[])
  const courseCount = useSelector(state => state.courseList.courses)
  const userCount = useSelector(state => state.userList.userList)
  // console.log(useSelector(state => state))

  console.log(courseCount.length,userCount.length)
  return(
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-3 col-6" >
        <div className="small-box" style={{padding: '10px'}}>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <div className="inner">
            <PurchasedCourse    />
          </div>
          <div className="icon" style={{color: 'black'}}>
            <h3>{courseCount.length}</h3>
          </div>
        </div>
        <p style={{color: 'rgba(0,0,0,.15)',zIndex: '0'}}>Total  Courses</p>
        </div>
      </div>
      {/* <div className="col-lg-3 col-6">
        <div className="small-box" style={{padding: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <div className="inner">
              <Skilllevel />
            </div>
            <div className="icon" style={{color: 'black'}}>
              <h3>60%</h3>
            </div>
          </div>
          <p style={{color: 'rgba(0,0,0,.15)',zIndex: '0'}}>Skill level</p>
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="small-box" style={{padding: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <div className="inner">
              <TotalDuration  />
            </div>
            <div className="icon" style={{color: 'black'}}>
              <h3>571 hrs</h3>
            </div>
          </div>
          <p style={{color: 'rgba(0,0,0,.15)',zIndex: '0' , whiteSpace: 'nowrap'}}>Total Duration</p>
        </div>
      </div> */}
      <div className="col-lg-3 col-6"  >
        <div className="small-box" style={{padding: '10px'}}>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <div className="inner">
            <TotalStudents />
          </div>
          <div className="icon" style={{color: 'black'}}>
            <h3>{userCount.length}</h3>
          </div>
        </div>
        <p style={{color: 'rgba(0,0,0,.15)',zIndex: '0'}}>Total Students</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Cards