import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Col, Container, Form, FormGroup, Input, Navbar, Row } from 'reactstrap';
import { addstaticPageInitiate } from '../../redux/actionCreators/staticPageActions';
import { stringvalidator, toaster } from '../../redux/sagas/utils';
import CustomSidebar from '../sidebar';

const AddStaticPage = ({show}) => {
  const [pageTitle, setPageTitle] = useState('')
  const [metaKeyword, setmetaKeyword] = useState('')
  const [metaKeywordDescription, setmetaKeywordDescription] = useState('')
  const [pageDescription,setPagedescription] = useState('')

  const [siddebar,setSiddebar] = useState(true)
  const [selected,setSelected] = useState('dashboard')
  
  const changeSelected =() => {
    setSiddebar(!siddebar)
  }
  const [important, setImportant] = useState(false)
  const [id,setID] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  
  const handlecancel = () => {
    setID('')
    setPageTitle('')
    setmetaKeyword('')
    setPagedescription('')
    setmetaKeywordDescription('')
    history.push('/admin/pages')
  }

  const handleAddSubmit = () => {
    const data = {
      id: id,
      title: pageTitle,
      metaKeyword: metaKeyword,
      description: pageDescription ,
      metaDescription: metaKeywordDescription,
    }
    dispatch(addstaticPageInitiate(data))
    handlecancel()
  }

  const handlevalidate = (e) => {
    e.preventDefault();
    if(stringvalidator(pageTitle) && stringvalidator(metaKeyword) && stringvalidator(metaKeywordDescription) &&  stringvalidator(pageDescription)){
      handleAddSubmit()
      return
    }
    setImportant(true)
    toaster("error","Please fill the * marked field")
  }

  const Important = () => {
    return(
      <span style={{color: 'red'}}> * </span>
    )
  }

  const width = show ? '325px' : '84px'
  return (
    <div className="main" style={{marginLeft: width,marginTop:'96px'}}>
      <div>
        <h1>Manage Content</h1>
      </div>
      <div className="col-sm-3">
        {/* <Search
          dropdown={false}
          inputstate={search}
          dropdownstate={searchBy}
          handleSubmit={handleSubmit}
          dropdownarray={dropdownArray}
          inputchange={(value) => setsearch(value)}
          dropdownchange={(value) => setSearchBy(value)}
        /> */}
      </div>
    <div>
      <div style={{display: 'flex',justifyContent: 'flex-end'}}>
        <Button onClick={handlecancel} color="primary" >Cancel</Button>
      </div>
        <Form>
          <Container>
            <FormGroup>
              <Row>
                <Col style={{letterSpacing: '0px'}}>
                  Page Title :
                  {important && <Important />}
                </Col>
              </Row>
                <Input value={pageTitle} style={{fontSize:"30px",height: '50px'}} onChange={(e) => setPageTitle(e.target.value)} />
              </FormGroup>
              <Row xs="1">
                <Col style={{letterSpacing: '0px'}}>
                  Meta Keyword : {important && <Important />}
                </Col>
                <Col>
                  <textarea
                    value={metaKeyword}
                    style={{width: '100%'}}
                    onChange={(e) => setmetaKeyword(e.target.value)}
                  />
                </Col>
              </Row>
              <Row xs="2">
                <Col style={{letterSpacing: '0px'}}>
                  Meta Description : {important && <Important />}
                </Col>
                <Col>
                  <textarea
                    style={{width: '100%'}}
                    value={metaKeywordDescription}
                    onChange={(e) => setmetaKeywordDescription(e.target.value)}
                  />
                </Col>
              </Row>
              <Row xs="2">
                <Col style={{letterSpacing: '0px'}}>
                  Page Description :
                  {important && <Important />}
                </Col>
              </Row>
              <Row xs="2">
                <textarea
                  value={pageDescription}
                  onChange={(e) => setPagedescription(e.target.value)}
                  style={{minHeight: '200px', width: '98%', margin: '10px'}}
                />
              </Row>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button color="success" onClick={handlevalidate}>Submit</Button>
              </div>
            </Container>
          </Form>
        </div>
      </div>
  );
}

export default AddStaticPage;